import * as React from "react"
const Svgyeub = () => (
  
  <svg 
    className="svgyeubMain"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 640 480",
      
    }}
    viewBox="115 60 400 400">
    
    <g id="katman_2"><path d="m419.5 151.5 32-28 56 26-24 33z"/></g>
    <g id="katman_4"><path d="m391.5 176.5 28-25 64 31-20 28.9z"/></g>
    <g id="katman_2"><path d="m299.5 265.5 71-69 78 38-40 57z" /></g>
    <g id="katman_2"><path d="m350.5 122.5 34-32 53 26-34 31z"/></g>
    <g id="katman_4"><path d="m257.5 220.5 66.6-69-1.6-2-91 11 23 58z" /></g>
    <g id="katman_2"><path d="m367.6 181.5 35.9-34-53-25-28 27 1.6 2-5.5 5.7z" /></g>
    <g id="katman_2"><path d="m330.5 215.5 37.1-34-49-24.3-34.9 36.1z"/></g>
    <g id="katman_2"><path d="m246.5 226.5 8-8 3 2 26.2-27.2 46.8 22.2-40 40z"/></g>
    <g id="katman_2"><path d="m211.5 261.5 35-35 44 29-39.5 38.7z"/></g>
    <g id="katman_2"><path d="m176 294.2 35.5-32.7 39.5 32.7-17.5 18.3-7 22z"/></g>
    <g id="katman_2"><path d="m150.5 318.5 25.5-24.3 50.5 40.3-12 31z" /></g>
    <g id="katman_2"><path d="m127.5 340.5 23-22 64 47-11 29z"/></g>
    <g id="katman_4"><path d="m370.5 196.5 21-20 72 34.9-15 23.1z"/></g>
    <g id="katman_3"><path d="m370.5 83.8 14 6.7-62 59-25.1 3z" /></g>
    <g id="katman_3"><path d="m216.5 403.5 30-82.8 53-55.2 71-69 21-20 28-25 32-28-14-7-34 31-35.9 34-37.1 34-40 40-39.5 38.7-17.5 18.3-7 22-12 31-11 29z"/></g>
    
    <g id="P01"> <text id="P01" transform="rotate(27.27 -42.697 834.887)">{"P01-219.38 m\xB2"}</text> </g>
    <g id="P01"><text id="P02" transform="rotate(27.27 -127.873 776.093)">{"P02-219.38 m\xB2"}</text></g>
    <g id="P01"><text transform="rotate(27.27 -214.835 723.617)">{"P03-219.38 m\xB2"}</text></g>
    <g id="P01"><text transform="rotate(27.27 -310.16 672.536)">{"P04-226.00 m\xB2"}</text></g>
    <g id="P01"><text transform="rotate(27.27 -399.36 611.724)">{"P05-226.00 m\xB2"}</text></g>
    <g id="P01"><text transform="matrix(.7476 .4239 -.5689 .8224 199.974 284.863)">{"P06-226.00 m\xB2"}</text></g>
    <g id="P01"><text transform="rotate(27.27 -572.076 511.276)">{"P07-203.07 m\xB2"}</text></g>
    <g id="P01"><text transform="rotate(27.27 -628.983 483.613)">{"P08-226.00 m\xB2"}</text></g>
    <g id="P01"><text transform="rotate(27.27 -71.293 985.611)">{"P09-226.00 m\xB2"}</text></g>
    <g id="P02"><text transform="rotate(27.27 -144.066 946.52)">{"P10-226.00 m\xB2"}</text></g>
    <g id="P02"><text transform="rotate(27.27 -203.473 908.551)">{"P11-191.37 m\xB2"}</text></g>
    <g ><text id="P01" transform="rotate(27.27 -294.552 838.981)">{"P12-658.11 m\xB2"}</text></g>
    <g><text id="P02" transform="rotate(27.27 -224.94 595.12)">{"P13-253.66 m\xB2"}</text></g>
      
      <g id="katman_7">
      <path
        d="M426.5 327.5h15v15h-15z"
        style={{
          fill: "#ff8247",
          stroke: "#ff8247",
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M426.5 356.5h15v15h-15z"
        style={{
          fill: "#0f0",
          stroke: "#0f0",
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M426.5 385.5h15v15h-15z"
        style={{
          fill: "#3fa9f5",
          stroke: "#3fa9f5",
          strokeMiterlimit: 10,
        }}
      />
      <text className="legantText" transform="translate(451.392 341)">{"SATILDI"}</text>
      <text className="legantText" transform="translate(453 371.5)">{"SATI\u015ETA"}</text>
      <text className="legantText" transform="translate(451.95 399.5)">{"OPS\u0130YON"}</text>
    </g>
  </svg>
  
)
export default Svgyeub;
