import * as React from "react"
const Svgyuzbesbir = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 612 792",
      width:'500px'
    }}
    viewBox="0 0 612 792"
    {...props}
  >
    <style>
      {
        '.st0,.st1{fill:#c6c6c6}.st1{stroke:#000}.st1,.st3,.st4,.st5{stroke-miterlimit:10}.st3{fill:none;stroke:#fff;stroke-dasharray:12}.st4,.st5{fill:#f39200;stroke:#000}.st5{fill:#66b32e}.st6{font-family:"MyriadPro-Regular"}.st7{font-size:9px}.st11{stroke:#000;stroke-width:.25;stroke-miterlimit:10}.st12{font-size:7.92px}'
      }
    </style>
    <g id="yol">
      <path style={{fill: '#c6c6c6'}} d="m189.2 191.8 48.5 76.7 38.3 60.2 37.6 59.3 37.8 59.2 38.9 60.5 29.7 46.2 56.9 90 14.6-5.5-39.6-61.8-37.2-59.3-37.2-57.1-17.7-28.9-25.3-39.7-24.3-38.1-25.3-39.9-25.8-40.2-25.3-39.9-34.7-54.2z"/>
      <path style={{fill: '#c6c6c6'}} d="m325.1 17.8-54 75.4-72 86.1-9.9 12.5-79.3 96-65.8 78.8 14.4 45.9 16.4 52.9 13.8 47.5 14.1 43.1 11.8 39.6 11.4 38.9 6 19.8 17.5 7.8 44.6 20.6 61.9 27.2 77.7-20.9 76.2-23.2 67-21.9 14.6-5.5 9.4-2.6 77.5-66 33.6-19.6v40.7l-111.6 71.2-128.9 44.1L255.2 726l-135.8-60.2-92.7-302.9L313.6 17.8z"/>
      <path d="m271.1 93.2 54-75.4M578.4 569.8l33.6-19.6" style={{fill: "none",stroke: "black",strokeMiterlimit: 10,}}/>
      <path d="m612 590.9-111.6 71.2-128.9 44.1L255.2 726l-135.8-60.2-92.7-302.9L313.6 17.8" style={{fill: "none",stroke: "black",strokeMiterlimit: 10,}}/>
      <path d="m194.1 185.5 290.2 455.6M612 573.4l-29.7 14.4-72 56.1-12 5.4L368 692.7l-34.3 8.8-78 16.2L126 659.8 35 363.4 319.3 17.8" className="st3"/>
    </g>
    <g id="parseller">
        <g id="katman_2"><path d="m199.1 179.3 72-86.1 51.1 79.8-88.4 60.5z"/></g>
      
        <g id="katman_4"><path d="m233.8 233.5 88.4-60.5 21.1 34.4-87.2 60.6z"/></g>
        <g id="katman_4"><path d="m256.1 268 87.2-60.6 21.3 33.2-87.5 60.4z"/></g>
        <g id="katman_4"><path d="m277.1 301 87.5-60.4 20.4 32.2-87 60.9z"/></g>
        <g id="katman_2"><path d="M298 333.7l87-60.9 21.5 33.5-87 60.8z"/></g>
        <g id="katman_2"><path d="M406.5 306.3l-87 60.8 19.9 31.7 87.6-60.4z"/></g>
        <g id="katman_2"><path d="m339.4 398.8 20.4 32.5 90.5-59.5-23.3-33.4z"/></g>

        <g id="katman_2"><path d="m359.8 431.3 90.5-59.5 19.3 29.7-92.1 58.7z"/></g>
        <g id="katman_2"><path d="m377.5 460.2 92.1-58.7 37.1 56.9-92 58.9z"/></g>
        <g id="katman_2"><path d="m414.7 517.3 92-58.9 37.5 58.9-92.3 59.3z"/></g>
        <g id="katman_2"><path d="M451.9 576.6l92.3-59.3 34.2 52.5-77.5 66-9.4 2.6z"/></g>
        <g id="katman_2"><path d="M363.2 590.9l56.8-37 56.9 90-67 21.9z"/></g>
        <g id="katman_2"><path d="m290.1 619.9 20.9-13.6 8.3 12.5 43.9-27.9 46.7 74.9-18.3 6-25.3 8.1-32.6 9.1z"/></g>
        <g id="katman_2"><path d="m319.3 618.8 43.9-27.9 56.8-37-29.7-46.2-100.2 65.7 20.9 32.9z"/></g>
        <g id="katman_2"><path d="m290.1 573.4 100.2-65.7-38.9-60.5-99.4 65.7z"/></g>
        <g id="katman_2"><path d="M252 512.9l99.4-65.7-37.8-59.2-98.6 65.2z"/></g>
        <g id="katman_2"><path d="m215 453.2 98.6-65.2-37.6-59.3-99.3 62.9z"/></g>
        <g id="katman_2"><path d="m176.7 391.6 99.3-62.9-38.3-60.2-99.1 64.4z"/></g>
        <g id="katman_2"><path d="M138.6 332.9l99.1-64.4-48.5-76.7-79.3 96z"/></g>
        <g id="katman_2"><path d="m44.1 366.6 65.8-78.8 28.7 45.1 12.7 19.7-92.8 59.9z"/></g>
        <g id="katman_2"><path d="M58.5 412.5l92.8-59.9 25.4 39 3.6 5.9-105.4 67.9z"/></g>
        <g id="katman_2"><path d="M74.9 465.4l105.4-67.9 24.8 39.8-116.4 75.6z"/></g>
        <g id="katman_2"><path d="m88.7 512.9 116.4-75.6 22.7 36.6-125 82.1z"/></g>
        <g id="katman_2"><path d="M102.8 556l125-82.1 21.6 34.8-134.8 86.9z"/></g>
        <g id="katman_2"><path d="M114.6 595.6l134.8-86.9 2.6 4.2 17.6 27.8L126 634.5z"/></g>
        <g id="katman_2"><path d="M126 634.5l143.6-93.8 19.4 30.9-139.5 90.5-17.5-7.8z"/></g>
        <g id="katman_2"><path d="M149.5 662.1 289 571.6l1.1 1.8 20.9 32.9-20.9 13.6-96 62.8z"/></g>
        <g id="katman_2"><path d="m194.1 682.7 96-62.8 43.6 69.1-77.7 20.9z"/></g>
    </g>
    <g id="katman_4">
      <text
        id="P01"
        className="st6 st7"
        transform="rotate(-32.342 1271.695 -546.266)"
      >
        {"P01-318.36 m\xB2"}
      </text>
      <text
        id="P02"
        className="st6 st7"
        transform="rotate(-32.342 1152.9 -510.295)"
      >
        {"P02-318.36 m\xB2"}
      </text>
      <text
        id="P03"
        className="st6 st7"
        transform="rotate(-32.342 1041.875 -473.006)"
      >
        {"P03-318.36 m\xB2"}
      </text>
      <text
        id="P04"
        className="st6 st7"
        transform="rotate(-32.342 950.345 -452.43)"
      >
        {"P04-159.18 m\xB2"}
      </text>
      <text
        id="P05"
        className="st6 st7"
        transform="rotate(-32.342 881.158 -435.788)"
      >
        {"P05-182.95 m\xB2"}
      </text>
      <text
        id="P06"
        className="st6 st7"
        transform="rotate(-32.342 817.563 -416.66)"
      >
        {"P06-182.95 m\xB2"}
      </text>
      <text
        id="P07"
        className="st6 st7"
        transform="rotate(-32.342 749.517 -399.42)"
      >
        {"P07-182.95 m\xB2"}
      </text>
      <text
        id="P08"
        className="st6 st7"
        transform="rotate(-32.342 681.51 -380.132)"
      >
        {"P08-182.95 m\xB2"}
      </text>
      <text
        id="P09"
        className="st6 st7"
        transform="rotate(-32.342 616.173 -362.25)"
      >
        {"P09-182.95 m\xB2"}
      </text>
      <text
        id="P10"
        className="st6 st7"
        transform="rotate(-32.342 544.308 -342.675)"
      >
        {"P10-182.95 m\xB2"}
      </text>
      <text
        id="P11"
        className="st6 st7"
        transform="rotate(-32.342 449.924 -315.616)"
      >
        {"P11-371.42 m\xB2"}
      </text>


      <text
        id="P12"
        className="st6 st7"
        transform="rotate(-32.342 583.94 -101.632)"
      >
        {"P12-371.42 m\xB2"}
      </text>
      <text
        id="P13"
        className="st6 st7"
        transform="rotate(-32.342 698.056 -134.572)"
      >
        {"P13-371.42 m\xB2"}
      </text>
      <text
        id="P14"
        className="st6 st7"
        transform="rotate(-32.342 822.004 -170.519)"
      >
        {"P14-371.42 m\xB2"}
      </text>
      <text
        id="P15"
        className="st6 st7"
        transform="rotate(-32.342 945.315 -205.544)"
      >
        {"P15-371.42 m\xB2"}
      </text>
      <text
        id="P16"
        className="st6 st7"
        transform="matrix(.8449 -.535 .535 .8449 295 530.915)"
      >
        {"P16-371.42 m\xB2"}
      </text>
      <text
        id="P17"
        className="st6 st7"
        transform="rotate(-32.342 1171.138 -269.866)"
      >
        {"P17-278.57 m\xB2"}
      </text>
      <text
        id="P18"
        className="st6 st7"
        transform="rotate(-32.342 1274.294 -363.919)"
      >
        {"P18-278.57 m\xB2"}
      </text>
      <text
        id="P19"
        className="st6 st7"
        transform="rotate(-32.342 1300.724 -240.997)"
      >
        {"P19-278.57 m\xB2"}
      </text>
      <text
        id="20"
        className="st6 st7"
        transform="rotate(-32.342 1305.148 -79.717)"
      >
        {"P20-278.57 m\xB2"}
      </text>
      <text
        id="P21"
        className="st6 st7"
        transform="rotate(-32.342 1229.814 -26.799)"
      >
        {"P21-278.57 m\xB2"}
      </text>
      <text
        id="P22"
        className="st6 st7"
        transform="rotate(-32.342 1165.455 -9.413)"
      >
        {"P22-278.57 m\xB2"}
      </text>
      <text
        id="P23"
        className="st6 st7"
        transform="rotate(-32.342 1103.823 2.376)"
      >
        {"P23-278.57 m\xB2"}
      </text>
      <text
        id="P24"
        className="st6 st7"
        transform="rotate(-32.342 1029.726 19.123)"
      >
        {"P24-278.57 m\xB2"}
      </text>
      <text
        id="P25"
        className="st6 st7"
        transform="rotate(-32.342 957.768 31.86)"
      >
        {"P25-278.57 m\xB2"}
      </text>
      <text
        id="P26"
        className="st6 st7"
        transform="rotate(-32.342 874.29 39.389)"
      >
        {"P26-278.57 m\xB2"}
      </text>
      <text
        id="P27"
        className="st6 st7"
        transform="rotate(-32.342 779.026 52.38)"
      >
        {"P27-278.57 m\xB2"}
      </text>
      <text
        id="P28"
        className="st6 st7"
        transform="rotate(-32.342 683.91 66.993)"
      >
        {"P28-278.57 m\xB2"}
      </text>
    </g>
    <g id="katman_5">
      <path
        d="M506.4 143.6h9.9v9.9h-9.9z"
        style={{
          fill: "#f0804d",
          stroke: "#f0804d",
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M506.4 162.7h9.9v9.9h-9.9z"
        style={{
          fill: "#00FF00",
          stroke: "#00FF00",
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M506.4 181.9h9.9v9.9h-9.9z"
        style={{
          fill: "#54a4db",
          stroke: "#54a4db",
          strokeMiterlimit: 10,
        }}
      />
      <text className="st11 st6 st12" transform="translate(522.839 152.494)">
        {"SATILDI"}
      </text>
      <text className="st11 st6 st12" transform="translate(523.89 172.626)">
        {"SATI\u015ETA"}
      </text>
      <text className="st11 st6 st12" transform="translate(523.187 191.105)">
        {"OPS\u0130YON"}
      </text>
    </g>
  </svg>
)
export default Svgyuzbesbir;
