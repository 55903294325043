import {createSlice} from '@reduxjs/toolkit';
import GeneralDatas from '../datas/GeneralDatas';

const initialState = {
    cartItems:GeneralDatas,
    href:'',
}

const cartSlice = createSlice ({
    name:'cart',
    initialState,
    reducers: {
        linkToGenel: (state, action) => {
            const itemId=action.payload;
            const href=state.cartItems.reduce((value,cartItems) => {
                if (cartItems.id===itemId) {value=cartItems.href}
                return value;
            },'')
            state.href=href;
        },
    },
});

export const {linkToGenel, href} = cartSlice.actions;
export default cartSlice.reducer;