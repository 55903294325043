import * as React from "react"
const Svgyuzkirkdordedort = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 400 400",
    }}
    viewBox="0 0 400 400"
    {...props}
  >
    <style>
      {
        '.st4{fill:#1d1d1b;stroke:#1d1d1b;stroke-width:.25;stroke-miterlimit:10}.st5{font-family:"MyriadPro-Regular"}.st6{font-size:6.4152px}.st8{fill:none;stroke:#000}.st10,.st8,.st9{stroke-miterlimit:10}.st9{fill:none;stroke:#fff;stroke-width:.5;stroke-dasharray:5}.st10{fill:#fbb03b;stroke:#000}.st11{font-size:6px}'
      }
    </style>
    <g id="katman_4">
      <path
        d="M63.3 343.6h8v8h-8z"
        style={{
          fill: "#fbb03b",
          stroke: "#fbb03b",
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M63.2 359.1h8v8h-8z"
        style={{
          fill: "#0f0",
          stroke: "#0f0",
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M63.2 374.6h8v8h-8z"
        style={{
          fill: "#53a2da",
          stroke: "#53a2da",
          strokeMiterlimit: 10,
        }}
      />
      <text className="st4 st5 st6" transform="translate(76.575 350.846)">
        {"SATILDI"}
      </text>
      <text className="st4 st5 st6" transform="translate(77.426 367.152)">
        {"SATI\u015ETA"}
      </text>
      <text className="st4 st5 st6" transform="translate(76.857 382.12)">
        {"OPS\u0130YON"}
      </text>
    </g>
    <g id="katman_3">
      <path
        d="m375.5 254.5-41-35-29-26-29-25-28-24-17-15-25-20-22.4-22.8-44.6-45.2-8 2-19.4 62.5-23.6 61.5 39 29 49 35 34 25 36 26 37 27 64 47-1 2-5 5-70.6-49.6-65.4-48.4-43-31-42-31-45-32 26-64 17-56-5-18 14-20 82 85 32 27 134 116z"
        style={{
          fill: "#ccc",
          stroke: "#ccc",
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="m127.5 12.5 82 86 32 27 134 116M75.5 171.5l26-64 17-56-6-19"
        className="st8"
      />
      <path
        d="m117.9 27.1 6.6 22.4-17 58-25 62 262 190M122.5 20.5l22 17 63 67L375.6 248"
        className="st9"
      />
    </g>
    <g id="katman_2">
      <path d="m131.5 43.5 8-2 45 46-38 46-34-28z" className="st10" />
      <path
        d="m146.5 133.5 37.6-46.8 4.4 4.8 18 18 25 20-36 44z"
        className="st10"
      />
      <path d="m195.5 173.5 36-44 17 15-36 43-2-2z" className="st10" />
      <path d="m212.5 187.5 36-43 28 24-34 43z" className="st10" />
      <path d="m242.2 210.9 29.3 23.6 34-41-29-25z" className="st10" />
      <path
        d="m271.5 234.5 6 5 24 20 33-40-29-26zM301.5 259.5l12 9 53 43 7-11 2-46-41-35z"
        className="st10"
      />
      <path d="m366.5 311.5-16 43-3 2-64-47 30-41z" className="st10" />
      <path d="m283.5 309.5 30-41-12-9-24-20-31 43z" className="st10" />
      <path d="m246.5 282.5 31-43-6-5-28.2-22.4-32.8 44.4z" className="st10" />
      <path
        d="m210.5 256.5 32.8-44.4-1.1-1.2-29.7-23.4-2-2-34 46zM176.5 231.5l34-46-15-12-32.2-26.4-35.8 49.4zM127.5 196.5l35.8-49.4-16.8-13.6-34-28-4 10-20 52zM313.5 395.5l28-32-70-50-25 35z"
        className="st10"
      />
      <path d="m246.5 348.5 25-35-66-48-25 37z" className="st10" />
      <path d="m180.5 302.5 25-37-43-31-26 38z" className="st10" />
      <path d="m136.5 272.5 26-38-42-31-26 39z" className="st10" />
      <path d="m94.5 242.5 26-39-45-32-9 25-11 19z" className="st10" />
    </g>
    <g id="katman_5">
      <text
        id="P01_00000017516370885735093490000014956154312019831741_"
        className="st5 st11"
        transform="rotate(-49.754 183.946 -94.63)"
      >
        {"P01-375.00 m\xB2"}
      </text>
      <text
        id="P01_00000017516370885735093490000014956154312019831741_"
        className="st5 st11"
        transform="rotate(-49.754 246.748 -122.858)"
      >
        {"P02-375.00 m\xB2"}
      </text>
      <text
        id="P01_00000150817975957143127770000016553862586153404329_"
        className="st5 st11"
        transform="rotate(-49.754 292.41 -144.065)"
      >
        {"P03-107.14 m\xB2"}
      </text>
      <text
        id="P01_00000143603576269232328850000006185267985989235124_"
        className="st5 st11"
        transform="rotate(-49.754 324.797 -156.827)"
      >
        {"P04-214.28 m\xB2"}
      </text>
      <text
        id="P01_00000151514172105603650380000015200277981716392618_"
        className="st5 st11"
        transform="rotate(-49.754 364.136 -177.505)"
      >
        {"P05-214.28 m\xB2"}
      </text>
      <text
        id="P01_00000103242372478681530780000008888058125023000473_"
        className="st5 st11"
        transform="rotate(-49.754 405.643 -195.4)"
      >
        {"P06-214.28 m\xB2"}
      </text>
      <text
        id="P01_00000080188455217573059650000014440686871618120625_"
        className="st5 st11"
        transform="rotate(-49.754 460.816 -220.986)"
      >
        {"P07-375.00 m\xB2"}
      </text>
      <text
        id="P01_00000052826529414923672920000007225558899234058140_"
        className="st5 st11"
        transform="rotate(-53.962 482.071 -151.28)"
      >
        {"P08-375.00 m\xB2"}
      </text>
      <text
        id="P01_00000176010768031600690080000009131085170134438019_"
        className="st5 st11"
        transform="rotate(-53.962 421.198 -122.812)"
      >
        {"P09-250.00 m\xB2"}
      </text>
      <text
        id="P01_00000173133070123523956270000015246532949193789365_"
        className="st5 st11"
        transform="rotate(-53.962 375.212 -100.478)"
      >
        {"P10-250.00 m\xB2"}
      </text>
      <text
        id="P01_00000045586632248544674480000015997232257463906950_"
        className="st5 st11"
        transform="rotate(-53.962 332.191 -79.109)"
      >
        {"P11-250.00 m\xB2"}
      </text>
      <text
        id="P01_00000170259607719330096700000013864751116316749483_"
        className="st5 st11"
        transform="rotate(-53.962 282.044 -56.24)"
      >
        {"P12-375.00 m\xB2"}
      </text>
      <text
        id="P01_00000097493062259803356660000007555038520033880195_"
        className="st5 st11"
        transform="rotate(-53.962 225.964 -28.984)"
      >
        {"P13-375.00 m\xB2"}
      </text>
      <text
        id="P01_00000028285730678928642270000013622544013761602434_"
        className="st5"
        style={{
          fontSize: 5,
        }}
        transform="rotate(-56.399 247.135 36.157)"
      >
        {"P14-250.00 m\xB2"}
      </text>
      <text
        id="P01_00000058585155918608593590000007311704820528138413_"
        className="st5 st11"
        transform="rotate(-56.399 297.785 12.807)"
      >
        {"P15-250.00 m\xB2"}
      </text>
      <text
        id="P01_00000070076794041606249030000016127451832545819066_"
        className="st5 st11"
        transform="rotate(-56.399 348.917 -11.228)"
      >
        {"P16-250.00 m\xB2"}
      </text>
      <text
        id="P01_00000137826069762246050020000003958788341358745779_"
        className="st5"
        style={{
          fontSize: 7,
        }}
        transform="rotate(-56.399 410.906 -43.23)"
      >
        {"P17-375.00 m\xB2"}
      </text>
      <text
        id="P01_00000030471342573040849430000010817080688821856918_"
        className="st5 st11"
        transform="rotate(-56.399 490.587 -81.354)"
      >
        {"P18-375.00 m\xB2"}
      </text>
    </g>
  </svg>
)
export default Svgyuzkirkdordedort;
