import React , { Fragment } from 'react';
import {Outlet} from 'react-router-dom';
import {Container,Grid,Typography} from '@mui/material'
import {useSelector} from 'react-redux';
import ProjectItems from '../helpers/ProjectItems';
import AdPtojrcts from '../reklam/AdPtojrcts';


function Projects() {
  const {dataItems} = useSelector ((store) => store.dataR)
  return (
    <Fragment>
      <AdPtojrcts/>
      <Container fixed sx={{marginBottom:3,marginTop:2}}>
      <Typography variant='h4' component='h4' sx={{marginBottom:2, textAlign:'center'}}>Tüm Projelerimiz</Typography>
        <Grid fixed sx={{marginBottom:3}}>
          <Grid sx={{display:'flex', flexDirection:'row',flexWrap:'wrap', justifyContent:'center',gap:'20px'}}>
              {dataItems.map((Item, key) => {
                return(
                  <ProjectItems
                  key={key}
                  {...Item}
                  loading='lazy'
                  />
                )
              })}
          </Grid>
        </Grid>
      </Container>
      <Outlet/>
    </Fragment>
  )
}

export default Projects;