import * as React from "react"
const Svgyuzellibironalti = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 400 400",
    }}
    viewBox="0 0 400 400"
    {...props}
  >
    <style>
      {
        '.st1{fill:#b3b3b3;stroke:#b3b3b3;stroke-miterlimit:10}.st2{fill:none;stroke:#000;stroke-width:1}.st2,.st3,.st4,.st6{stroke-miterlimit:10}.st3{fill:none;stroke:#fff;stroke-width:.5;stroke-dasharray:7}.st4,.st6{fill:#0f0;stroke:#000;stroke-width:1}.st6{fill:#fbb03b}.st7{font-family:"MyriadPro-Regular"}.st8{font-size:6px}.st12{fill:#1d1d1b;stroke:#1d1d1b;stroke-width:.25;stroke-miterlimit:10}.st13{font-size:7.92px}'
      }
    </style>
    <g id="katman_3">
      <path
        d="m220.2 105 8.2 10.1-20.3 15.5-67 90.4 25 15.2 16.5 12.2 21.6 12.5 20.5 11.7 18.7 13-5.7 11.7-19.5-11.8-21.7-13.6L8.3 153l6.9-9.8 34.8 22 15.1 8.3 16.4 11.2 24.8 14.2 15.2 9.3 8.8 6 14.8-19.8 53.7-73.8z"
        className="st1"
      />
      <path
        d="m134.6-2.2 24.6 29.3 23.7 30.4 18.9 24 18.4 23.5 8.2 10.1 9 12.6 19.4 18.8 44.2 17 32.4 1.2 13.1.5 53.9-4.3-3.6-11.3-30.8 1.3-23.9 1.8-21.3-.6h-17.1l-20-7.7-20.2-8.7-12.5-12.6-10.5-11.9-20.2-26.8-20.1-24.9-33.1-42.4L149.5-4z"
        className="st1"
      />
      <path
        d="m149.5-4 17.6 21.1 50.2 63.1M134.6-2.2l26.8 33 40.4 50.7 18.4 23.5-21.4 15.6M208.1 130.6l20.3-15.5 9 12.6 19.4 18.8 44.2 17 45.5 1.7 53.9-4.3"
        className="st2"
      />
      <path
        d="m143-2.8 42.2 54 21.2 26.7 14 16.5 21.5 28.7 18.2 18 44.3 17 27.3.8 14.1.4 53.1-4.7M224.5 109.5l-19.7 14.9-72 98.1M11.8 148.1 65.6 182l134.8 84.9 40.8 24.2"
        className="st3"
      />
    </g>
    <g id="katman_2">
      <g id="katman_4"><path d="M318.8 74.8 312 128l21.9-1.6 3.8-27.6z"/></g>
      <g id="katman_4"><path d="M265.1 41.4l-47.8 38.8 34.4 27.2 30.5-57z"/></g>
      <g id="katman_4"><path d="m251.7 107.4 30.9 23.5 19.9-70.8-20.3-9.7z"c/></g>
      <g id="katman_4"><path d="M302.5 60.1l16.3 14.7L312 128l-29.4 2.9z"c/></g>
      <g><path d="m217.3 80.2 34.4 27.2 30.9 23.5 30.3-2.6 22.6-1.6 2.9-1.3 56.9 13.5 1.2 9.9-54 3.3-39.4-1.4-22.2-8.5-16.8-6.1-17.2-18.1z" style={{fill: "#c7b299",stroke: "#000",strokeWidth: 1, strokeMiterlimit: 10,}}/></g>
      <g id="katman_2"><path d="m337.7 98.8 2.9 3.2 54.7 36.9-56.9-13.5-4.5 1z"/></g>
      <g id="katman_2"><path d="M109.3 26.4l29.5 28.8-88.8 110-34.8-22z"/></g>
      <g id="katman_2"><path d="m138.8 55.2 12.6 12.6-86.3 105.7-15.1-8.3z"/></g>
      <g id="katman_4"><path d="M151.4 67.8 165 81.3 81.5 184.7l-16.4-11.2z"/></g>
      <g id="katman_2"><path d="m165 81.3 13.7 13.5 4.9 7.5-77.3 96.6-24.8-14.2z"/></g>
      <g id="katman_2"><path d="M183.6 102.3l12.8 14.6-74.8 92.3-15.3-10.3z"/></g>
      <g id="katman_2"><path d="m196.4 116.9 2.4 3.7-68.5 93.6-8.7-5z"/></g>
      <g id="katman_4"><path d="m208.1 130.6 16.6 21.2-58.6 84.4-25-15.2z"/></g>
      <g id="katman_4"><path d="M224.7 151.8l7.1 9.3 3.9 7.5-53.1 79.8-16.5-12.2z"/></g>
      <g id="katman_4"><path d="m235.7 168.6 4.3 10.6 9.4 2.9-45.2 78.8-21.6-12.5z"/></g>
      <g id="katman_4"><path d="M249.4 182.1l9.4 3 8.6 5-42.7 82.5-20.5-11.7z"/></g>
      <g id="katman_4"><path d="m267.4 190.1 11 7.8-4.8 19.7-30.2 68-18.7-13z"/></g>
      <g id="katman_2"><path d="m8.3 153 188.2 118.9-41.8 73.4z"/></g>
      <g id="katman_4"><path d="m196.5 271.9 21.7 13.6-44.8 81.8-6.5-4.3-12.2-17.7z"/></g>
      <g id="katman_4"><path d="M218.2 285.5l19.5 11.8-44.2 86.7-20.1-16.7z"/></g>
    </g>
    <g id="katman_4">
      <text id="P011" transform="rotate(-50.409 194.505 28.86)">{"P01-639.80 m\xB2"}</text>
      <text id="P011" transform="rotate(-50.409 212.933 16.717)">{"P02-249.99 m\xB2"}</text>
      <text id="P011" transform="rotate(-50.409 231.057 5.782)">{"P03-285.37 m\xB2"}</text>
      <text id="P011" transform="rotate(-50.409 259.992 -14.338)">{"P04-387.88 m\xB2"}</text>
      <text id="P011" transform="rotate(-50.409 277.11 -25.864)">{"P05-240.06 m\xB2"}</text>
      <text id="P011" transform="rotate(-52.64 278.595 -25.401)">{"P06-193.94 m\xB2"}</text>
      <text id="P011" transform="rotate(-54.245 309.583 -44.911)">{"P07-313.42 m\xB2"}</text>
      <text id="P011" transform="rotate(-54.245 329.764 -54.878)">{"P08-213.26 m\xB2"}</text>
      <text id="P011" transform="rotate(-59.057 328.652 -49.893)">{"P09-213.26 m\xB2"}</text>
      <text id="P011" transform="rotate(-61.831 336.835 -51.669)">{"P10-213.28 m\xB2"}</text>
      <text id="P011" transform="rotate(-67.058 333.266 -42.375)">{"P11-193.94 m\xB2"}</text>
      <text id="P011" transform="rotate(-61.831 389.059 -7.75)">{"P12-225.00 m\xB2"}</text>
      <text id="P011" transform="rotate(-60.929 374.354 1.396)">{"P13-225.00 m\xB2"}</text>
      <text id="P011" transform="rotate(-60.929 350.778 11.982)">{"P14-904.16 m\xB2"}</text>
      <text id="P011" transform="rotate(-60.929 212.669 -161.909)">{"P15-165.47 m\xB2"}</text>
      <text id="P011" transform="rotate(-74.496 222.88 -123.014)">{"P16-165.47 m\xB2"}</text>
      <text id="P011" transform="rotate(-81.524 227.607 -116.921)">{"P17-165.47 m\xB2"}</text>
      <text id="P011" transform="rotate(-81.524 233.71 -123.23)">{"P18-45.16 m\xB2"}</text>
      <text id="P011" transform="rotate(13.555 -347.38 1489.813)">{"P19-121.62 m\xB2"}</text>
    </g>
    <g id="katman_5">
      <path
        d="M335.9 325.9h9.9v9.9h-9.9z"
        style={{
          fill: "#fbb03b",
          stroke: "#fbb03b",
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M335.9 345.1h9.9v9.9h-9.9z"
        style={{
          fill: "#0f0",
          stroke: "#0f0",
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M335.9 364.2h9.9v9.9h-9.9z"
        style={{
          fill: "#53a2da",
          stroke: "#53a2da",
          strokeMiterlimit: 10,
        }}
      />
      <text className="st12 st7 st13" transform="translate(352.316 334.831)">
        {"SATILDI"}
      </text>
      <text className="st12 st7 st13" transform="translate(353.367 354.964)">
        {"SATI\u015ETA"}
      </text>
      <text className="st12 st7 st13" transform="translate(352.665 373.443)">
        {"OPS\u0130YON"}
      </text>
    </g>
  </svg>
)
export default Svgyuzellibironalti;
