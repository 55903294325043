import React from 'react';
import {Outlet} from 'react-router-dom';

function History() {
  return (
    <>
        <div style={{marginTop:90}}>Tarihimiz</div>
        <Outlet/>
    </>

  )
}

export default History;