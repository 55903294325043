import React, {useState}  from 'react'
import '../parseller/./gallery.css'
import '../parseller/parselDetail.css';

import CloseIcon from '@mui/icons-material/Close';
import { Container,Box,Paper,Grid,Typography } from '@mui/material';
import Svgyuzaltiyauc from '../components1/Svgyuzaltiyauc';
import Registration from '../components/Registration';
import rsm1014 from '../imageproje/120-3/02.avif';
import img1 from '../imageproje/120-3/01.avif';
import img2 from '../imageproje/120-3/03.avif';
import img3 from '../imageproje/120-3/04.avif';
import img4 from '../imageproje/120-3/05.avif';
import img5 from '../imageproje/120-3/06.avif';
import img6 from '../imageproje/120-3/07.avif';
import img7 from '../imageproje/120-3/08.avif';
import img8 from '../imageproje/120-3/09.avif';
import img9 from '../imageproje/120-3/10.avif';


const ByuzyirmiyeUc = () => {
    const [model, setModel] = useState(false);
    const [tempimgSrc, setTempimgSrc] = useState('');
    const getImg = (imgSrc) => {
        setTempimgSrc(imgSrc);
        setModel(true);
    }
  return (
    <>
        <img src={rsm1014} alt="Belgrat Mahallesi" style={{width:'100%',marginTop:'83PX'}} loading='lazy'/>
        <Container className='parselWriting'>
            <Typography variant='h5' component='h5' sx={{marginTop:3}}>
                İstanbul, Çatalca, Belgrat Mahallesi, Köyiçi 1 Projesi
            </Typography>
            <Typography variant='body1' component='p' sx={{marginTop:3}}>
                İstanbul ilinin Çatalca ilçesine bağlı Belgrat Mahallesi'nde yer alan bu özel proje, 1 adet arsadan oluşmaktadır. Arsamız 359.00 m² büyüklüğe sahiptir. Arsanın içinde tarihi bina var, ruhsat alınarak yeni yapı yapılabilir. Arsa elektrik, doğalgaz ve su altyapısına yvardır.
                <br/>
                <br/>
                Projemiz, İstanbul bölgesinin hem turistik hem de tarımsal ve sanayi açısından gözde ilçelerinden biri olan Çatalcanın'ın Belgrat Mahallesi yerleşimine yürüme mesafesinde konumlanmıştır. Çatalca ilçe merkezine 35 km, İstanbul il merkezine 75 km, Çatalca guzide plajlarından Yalıköye'e 15 km, Karacaköy Evcik Plajına 5 km, Ormanlı Plajına 8 km ve Çilingoza'ya 25 km, uzaklıktadır. 
                <br/>
                <br/>
                İstanbul şehir merkezine yakın olduğundan ulaşımı kolaydır. Arazinize yaz, kış rahatlıkla ulaşabilirsiniz. Günlük olarak gelebilir, meşe ormanlarının dibinde, kuş cıvıltıları içinde dinlenebilir ve takip eden günde işinize dinlenik olarak gidebilirsiniz.
                <br/>
                <br/>
                Bu eşsiz projede, doğanın ve huzurun tadını çıkarırken modern yaşamın tüm olanaklarına sahip olabilirsiniz!
            </Typography>
        </Container>
        <Container fixed sx={{marginTop:3, marginBottom:3}}>
            <Grid className='parselSvg' sx={{display:'flex', justifyContent:'space-around', alignContent:'center'}} gap={2}>
                <Box sx={{width:'50%'}}>
                    <Paper elevation={8} sx={{width:'100%'}}>
                        <Svgyuzaltiyauc/>
                    </Paper>
                </Box>
                <Box sx={{width:'50%', justifyContent:'space-around', alignContent:'center'}}>
                    <Paper elevation={8} sx={{width:'100%'}}>
                        <Typography variant='subtitle1' component='h6'>
                            Parselimiz İstanbul, Çatalca, Belgart Mahallesindedir.
                        </Typography>
                        <Typography variant='body2' component='p'>
                            Köye merkezine 200 metre mesafededir.
                        </Typography>
                    </Paper>
                </Box>
            </Grid>
        </Container>
        <Container>
            <Typography variant='h5' component='h5' style={{textAlign:'center',marginBottom:'20px'}}>
                PROJE AİT GÖRSELLER
            </Typography>
        </Container>
        <Container>
            <div className={model ? "model open" : "model"} >
                <img src={tempimgSrc} alt='Çatalca'loading='lazy'/>
                <CloseIcon onClick={() => setModel(false)}/>
            </div>
        </Container>
        <Container>
            <div className='gallery galaryList'>
                {DataItem1063.map((item, index) =>{
                    return(
                        <div className='pics' key={index} onClick={() => getImg(item.imgSrc)}>
                            <img src={item.imgSrc} alt='Çatalca' style={{width:'100%'}} loading='lazy'/>
                        </div>
                    )
                })}
            </div>
        </Container>
        <Container className='parselContact'>
            <Registration/>
        </Container>

    </>   
  )
}

export default ByuzyirmiyeUc;

const DataItem1063 = [
    {   id:1, imgSrc:img1},
    {   id:2, imgSrc:img2},
    {   id:3, imgSrc:img3},
    {   id:4, imgSrc:img4},
    {   id:5, imgSrc:img5},
    {   id:6, imgSrc:img6},
    {   id:7, imgSrc:img7},
    {   id:8, imgSrc:img8},
    {   id:9, imgSrc:img9},
]
