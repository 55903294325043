import React from 'react';
import '../Style/Footer.css';
import {Grid,Typography,Divider, Stack} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

function FooterUp() {
  return (
    <Grid className='infoGeneral'>
        <Grid className='infoMain'>
            <Grid className='infoItem'>
                <Typography variant='h5' component='h5' sx={{margin:1,color:'white'}}>
                    İLETİŞİM
                </Typography>
                <Divider variant='fullWith' component='hr' style={{background:'white'}} sx={{margin:1, border:2, opacity:1}}/>
                <Typography variant='Body2' component='div' sx={{margin:1,color:'white'}}>
                    Belgrat Mah, Mısra Sk, No: 6 / 1 Çatalca / İstanbul
                </Typography>
                <Typography variant='Body2' component='a' sx={{margin:1}}>
                    info@emlakçın.com
                </Typography>
                <Typography variant='Body2' component='div' sx={{margin:1}}>
                    Tel : 0 532 200 59 89
                </Typography>
                <Typography variant='Body2' component='div' sx={{margin:1}}>
                    Çalışma Saatleri: 09:00 - 18:00
                </Typography>
            </Grid>
            <Grid className='infoItem'>
            <Typography variant='h5' component='h5' sx={{margin:1}}>
                KURUMSAL
            </Typography>
            <Divider variant='fullWith' component='hr' sx={{margin:1, border:2, opacity:1}}/>
            <Stack direction='row'>
                <ChevronRightIcon sx={{marginTop:'8px'}}/>
                <Typography variant='Body2' component='a' sx={{margin:1}} href='/hakkımızda/ekibimiz'>
                    Ekibimiz
                </Typography>
            </Stack>
            <Stack direction='row'>
                <ChevronRightIcon sx={{marginTop:'8px'}}/>
                <Typography variant='Body2' component='a' sx={{margin:1}} href='/hakkımızda/tarihimiz'>
                    Tarihçemiz
                </Typography>
            </Stack>
            <Stack direction='row' >
                <ChevronRightIcon sx={{marginTop:'8px'}}/>
                <Typography variant='Body2' component='a' sx={{margin:1}} href='/hakkımızda/hedefimiz'>
                    Hedefimiz
                </Typography>
            </Stack>
            <Stack direction='row' >
            <ChevronRightIcon sx={{marginTop:'8px'}}/>
                <Typography variant='Body2' component='a' sx={{margin:1}} href='/iletisim'>
                    İletişim
                </Typography>
            </Stack>
            </Grid>
            <Grid className='infoItem'>
            <Typography className='infoItemH5' variant='h5' component='h5' sx={{margin:1}}>
                HIZLI ULAŞIM
            </Typography>
            <Divider variant='fullWith' component='hr' sx={{margin:1, border:2, opacity:1}}/>
            <Stack direction='row' >
                <ChevronRightIcon sx={{marginTop:'8px'}}/>
                <Typography variant='Body2' component='a' sx={{margin:1}} href='/projeler/emlak'>
                    Emlak
                </Typography>
            </Stack>
            <Stack direction='row' >
                <ChevronRightIcon sx={{marginTop:'8px'}}/>
                <Typography variant='Body2' component='a' sx={{margin:1}} href='/projeler/arsa'>
                    Arsa
                </Typography>
            </Stack>
            <Stack direction='row' >
                <ChevronRightIcon sx={{marginTop:'8px'}}/>
                <Typography variant='Body2' component='a' sx={{margin:1}} href='/projeler/inşaat'>
                    İnşaat
                </Typography>
            </Stack>
            <Stack direction='row' >
                <ChevronRightIcon sx={{marginTop:'8px'}}/>
                <Typography variant='Body2' component='a' sx={{margin:1}} href='/projeler/tumprojeler'>
                    Tüm Projeler
                </Typography>
            </Stack>
            <Stack direction='row' >
                <ChevronRightIcon sx={{marginTop:'8px'}}/>
                <Typography variant='Body2' component='a' sx={{margin:1}} href='/projeler/planlananprojeler'>
                    Planlanan Projeler
                </Typography>
            </Stack>
            </Grid>
        </Grid>
    </Grid>
  )
}

export default FooterUp