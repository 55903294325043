import * as React from 'react';
import '../Style/PermenetQuestion.css';
import { Container } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function PermenetQuestion() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container style={{marginTop:90, marginBottom:20}} >
      <Typography variant='h4' component='h4' sx={{textAlign:'center', margin:2,color:'#3b4990'}}>
        SIKÇA SORULAN SORULAR
      </Typography>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{color:'#3b4990'}}/>}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography variant='h6' component='h6' sx={{color:'#3b4990'}}>
            Fiyat teklifi için ücret ödeyecekmiyim?
          </Typography>
          {/* <Typography sx={{ color: 'text.secondary' }}>I am an accordion</Typography> */}
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='Body1' component='p' sx={{color:'#3b4990'}}>
            emlakçın.com, gayrimenkulünüzü bölge yetkilileri ve danışmanları ile ücretsiz olarak değerleyip tarafınıza bildirir. Fiyatta anlaşılması durumunda, noter masrafları emlakçın.com tarafından karşılanmaktadır.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{color:'#3b4990'}}/>}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography variant='h6' component='h6' sx={{color:'#3b4990'}}>Hangi arsalar için fiyat teklifi alabilirim?</Typography>
          {/* <Typography sx={{ color: 'text.secondary' }}>
            You are currently not an owner
          </Typography> */}
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='Body1' component='p' sx={{color:'#3b4990'}}>
            Hizmet verdiğimiz şehirlerde bulunan hisseli veya tek tapu arsa, tarla, bağ, bahçe, zeytinlik, tarım arazisi, kamu arazisi, sit alanları, konut alanı, ticaret alanı, vb. gayrimenkulleriniz için fiyat teklifi alabilirsiniz.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{color:'#3b4990'}}/>}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography variant='h6' component='h6' sx={{color:'#3b4990'}}>
            emlakçın.com fiyat teklifi nasıl çalışıyor?
          </Typography>
          {/* <Typography sx={{ color: 'text.secondary' }}>
            Filtering has been entirely disabled for whole web server
          </Typography> */}
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='Body1' component='p' sx={{color:'#3b4990'}}>
          Gayrimenkulünüzün mevcut durumu, hukuki durumu, imarı durumu, planı, konumu, bölgedeki arsa stoku, arz talep dengesi, hisse durumu arsanızın bulunduğu bölgedeki sosyal ekonomik koşullara göre emlakçın.com bölge yetkilisi ve gayrimenkul danışmanları tarafından ücretsiz olarak gerçekçi ve güncel fiyat belirlenip tarafınıza iletilir.
          <Typography variant='Body2' component='br'></Typography>
          <Typography variant='Body2' component='br'></Typography>
          Tarafınıza iletilen fiyatı beğenmediğiniz takdirde arsanızı satmak zorunda değilsiniz. Size özel atanacak bölge temsilcimiz ile istediğiniz zaman iletişime geçip güncel fiyat bilgisi alabilirsiniz.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{color:'#3b4990'}}/>}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography variant='h6' component='h6' sx={{color:'#3b4990'}}>
            Fiyat teklifi almak istiyorum. Tapu bilgilerini nasıl öğrenebilirim?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='Body1' component='p' sx={{color:'#3b4990'}}>
          Fiyat teklifi almak istediğiniz arsanızın tapu bilgisine www.turkiye.gov.tr adresinden | Kurumlar | Tapu Kadastro Genel Müdürlüğü | Tapu Bilgileri Sorgulama ekranından kolaylıkla ulaşabilirsiniz.

          Tapu bilgilerinize ulaşmada sorun yaşadığınız durumlarda çağrı merkezimizle iletişime geçip destek alabilirsiniz. 0 532 200 59 89
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{color:'#3b4990'}}/>}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography variant='h6' component='h6' sx={{color:'#3b4990'}}>Verilen fiyatı beğendim süreç nasıl işleyecek?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='Body1' component='p' sx={{color:'#3b4990'}}>
            Satış temsilcimiz sizinle iletişime geçip en uygun satış kanalı belirlenerek, satış için gün ve saat randevusu oluşturulur. 
            <Typography variant='Body2' component='br'></Typography>
            <Typography variant='Body2' component='br'></Typography>
            Tüm evrakların tamamlanmasının ardından satış başvurusu yapılır ve işlem imza aşamasına gelir.
            <Typography variant='Body2' component='br'></Typography>
            <Typography variant='Body2' component='br'></Typography>
            İmzalar atılma aşamasında nakit, eft/havale, TapuTakas sistemi ile tarafınıza anında ödeme yapılır.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{color:'#3b4990'}}/>}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography variant='h6' component='h6' sx={{color:'#3b4990'}}>Ödeme nasıl yapılacak?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='Body1' component='p' sx={{color:'#3b4990'}}>
            emlakçın.com imzalar atılma aşamasında (özel durumlarda imza atılmadan önce) sizin istediğiniz en güvenli yöntemle (nakit, eft/havale, TapuTakas sistemi vb.) tarafınıza anında ödeme yapar.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{color:'#3b4990'}}/>}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography variant='h6' component='h6' sx={{color:'#3b4990'}}>Fiyat teklifi nasıl ve ne kadar sürede yapışacak?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='Body1' component='p' sx={{color:'#3b4990'}}>
            Fiyat teklifiniz formdaki iletişim numaranız üzerinden kısa mesaj yoluyla ya da çağrı merkezimiz tarafında aranarak yapılmaktadır. emlakçın.com tarafından size sunulacak fiyat teklifi 1 saat ile 72 saat arasında tarafınıza iletilmiş olur.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{color:'#3b4990'}}/>}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography variant='h6' component='h6' sx={{color:'#3b4990'}}>Arsamı kim satın alacak? Herhangi bir ücret ödeyecekmiyim?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='Body1' component='p' sx={{color:'#3b4990'}}>
            Fiyatta anlaşma sağlandığı takdirde arsanızı yetkili satın alma uzmanımız emlakçın.com adına satın almaktadır.
            <Typography variant='Body2' component='br'></Typography>
            <Typography variant='Body2' component='br'></Typography>
              * Kesinlikle hiçbir bir ücret ödemeyeceksiniz.
            <Typography variant='Body2' component='br'></Typography>
              * Noter masrafları arsa.net tarafından karşılanmaktadır
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{color:'#3b4990'}}/>}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography variant='h6' component='h6' sx={{color:'#3b4990'}}>Satış koşulları nelerdir?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='Body1' component='p' sx={{color:'#3b4990'}}>
            Arsanın üzerinde haciz, ipotek ve vergi borcu bulunması durumunda eğer fiyatta anlaşma sağlandıysa emlakçın.com hukuk birimi tarafından gerekli işlemler yapılarak arsa satışı gerçekleşir.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{color:'#3b4990'}}/>}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography variant='h6' component='h6' sx={{color:'#3b4990'}}>Hisseli arsaları alıyormusunuz?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='Body1' component='p' sx={{color:'#3b4990'}}>
          Fiyat teklifinde anlaşılması durumunda hukuki problemi bulunmayan hisseli veya tek tapu tüm arsalarınızı alıyoruz.
          <Typography variant='Body2' component='br'></Typography>
          <Typography variant='Body2' component='br'></Typography>
          Hukuki problem bulunması durumunda, emlakkçın.com hukuk birimi tarafından çözümü için danışmanlık hizmeti verilmektedir.
          <Typography variant='Body2' component='br'></Typography>
          <Typography variant='Body2' component='br'></Typography>
          Hisseli veya tek tapu arsa, tarla, bağ, bahçe, zeytinlik, tarım arazisi, kamu arazisi, sit alanları, konut alanı, ticaret alanı, vb.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{color:'#3b4990'}}/>}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography variant='h6' component='h6' sx={{color:'#3b4990'}}>Hacizli ve davalı arsaları alıyormusunuz?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='Body1' component='p' sx={{color:'#3b4990'}}>
          Hacizli arsalarda; haczin arsa fiyatını geçmediği yada eşit olduğu durumlarda, haciz işlemlerini, hukuk birimimiz ile sonuçlandırarak satışını gerçekleştiriyoruz.
          <Typography variant='Body2' component='br'></Typography>
          <Typography variant='Body2' component='br'></Typography>
          emlakçın.com hukuk birimi tarafından davalı arsalarda hukuki danışmanlık hizmeti sunmaktayız. Detaylı bilgi için çağrı merkezimizle iletişime geçebilirsiniz.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{color:'#3b4990'}}/>}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography variant='h6' component='h6' sx={{color:'#3b4990'}}>Arsa ve ben ayrı şehirdeyim. Süreç nasıl işleyecek?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='Body1' component='p' sx={{color:'#3b4990'}}>
            * İkamet ettiğiniz tapu il müdürlüklerinden,
          <Typography variant='Body2' component='br'></Typography>
          <Typography variant='Body2' component='br'></Typography>
            * İkamet ettiğiniz şehirde noter aracılığı ile,
          <Typography variant='Body2' component='br'></Typography>
          <Typography variant='Body2' component='br'></Typography>
            * Gayrimenkulün  bulunduğu tapu il  müdürlüğünde,
          <Typography variant='Body2' component='br'></Typography>
          <Typography variant='Body2' component='br'></Typography>
            * Online olarak webtapu üzerinden işlemlerimizi gerçekleştiriyoruz.
          </Typography>
          <Typography variant='Body2' component='br'></Typography>
          <Typography>
            Noter hizmetleri emlakçın.com tarafından karşılanmaktadır.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{color:'#3b4990'}}/>}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography variant='h6' component='h6' sx={{color:'#3b4990'}}>Hangi şehirlerde hızmet veriyorsunuz?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='Body1' component='p' sx={{color:'#3b4990'}}>
            * Çanakkale, İstanbul, Kırklareli, Tekirdağ
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel14'} onChange={handleChange('panel14')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{color:'#3b4990'}}/>}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography variant='h6' component='h6' sx={{color:'#3b4990'}}>Arsa almayı düşünüyorum?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='Body1' component='p' sx={{color:'#3b4990'}}>
            Arsa almayı düşünen müşterilerimizi de önemsiyoruz. Yüksek kalitede, kişiye özel kurumsal danışmanlık hizmeti sunuyoruz. Çağrı merkezimizle iletişime geçerek, gayrimenkul yatırımı yapmayı düşündüğünüz bölgeyle ilgili kayıt oluşturabilirsiniz.  0 532 200 59 89
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
}

