import * as React from "react"
const Svgyuzbireyirmiiki = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 400 400",
    }}
    viewBox="0 0 400 400"
    {...props}
  >
    <style>
      {
        '.st1{fill:#ccc}.st2,.st3,.st4,.st5{fill:#0f0;stroke:#000;stroke-width:1;stroke-miterlimit:10}.st3,.st4,.st5{fill:#fbb03b}.st4,.st5{fill:none}.st5{stroke:#fff;stroke-width:none;stroke-dasharray:5}.st7{fill:#fff}.st8{font-family:"MyriadPro-Regular"}.st9{font-size:8px}.st13{fill:#1d1d1b;stroke:#1d1d1b;stroke-width:none;stroke-miterlimit:10}.st14{font-size:6.4152px}'
      }
    </style>
    <path
      id="katman_5"
      d="m28 344 5-13 48 13 45 5 11 2 46 13 35 15 15 9-9 10-42-21-45-9-44-10-49-10z"
      className="st1"
    />
    <path
      id="katman_3"
      d="m199.8 157.8 10.7 3.7L137 351l-11-2 22-57 23-61z"
      className="st1"
    />
    <g id="katman_2">
      <path d="m226.5 40.5 29 7-45 114-27-10z" id="katman_4" />
      <path d="M183.5 151.5 156 225l15 6 28.8-73.2z" id="katman_2" />
      <path d="m156 225 15 6-23 61-16-6z" id="katman_2" />
      <path d="m132 286 16 6-22 57-18-2z" id="katman_2" />
      <path d="M204.5 159.6 132 350.8M30.7 337l49.8 12.5 43 7 59 14 45.2 22.8" className="st5" />
      <path d="m33 331 48 13 27 3M28 344l14 4 53 10 37 9 14 3 36 7 43 21M210.5 161.5 137 351l46 13 35 15 15 10" className="st4" />
    </g>
    <g id="katman_4">
      <path d="m177 25 7-21 46 14 25 6 72 13 20-3 2 24-22 2-71.5-12.5-29-7z" style={{fill: "#0ff"}} stroke="none"/>
      <path d="m349 58-22 2-71.5-12.5-29-7L177 25M184 3l48 15 24 6 18 3 27 5 26 5 20-3" className="st4"/>
    </g>
    <g id="katman_6">
      <text className="st7 st8 st9" transform="matrix(.3667 -.9976 .9386 .345 122.985 342.677)">{"P01-172.60 m\xB2"}</text>
      <text className="st7 st8 st9" transform="matrix(.3667 -.9976 .9386 .345 145.808 282.62)">{"P02-183.78 m\xB2"}</text>
      <text className="st7 st8 st9" transform="matrix(.3667 -.9976 .9386 .345 171.808 215.62)">{"P03-220.20 m\xB2"}</text>
      <text className="st7 st8 st9" transform="matrix(.3667 -.9976 .9386 .345 211.808 126.62)">{"P04-565.42 m\xB2"}</text>
    </g>
    <g id="katman_7">
      <path
        d="M295.2 335.5h8v8h-8z"
        style={{
          fill: "#fbb03b",
          stroke: "#fbb03b",
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M295.2 351h8v8h-8z"
        style={{
          fill: "#0f0",
          stroke: "#0f0",
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M295.2 366.5h8v8h-8z"
        style={{
          fill: "#53a2da",
          stroke: "#53a2da",
          strokeMiterlimit: 10,
        }}
      />
      <text className="st13 st8 st14" transform="translate(308.516 342.72)">{"SATILDI"}</text>
      <text className="st13 st8 st14" transform="translate(309.366 359.028)">{"SATI\u015ETA"}</text>
      <text className="st13 st8 st14" transform="translate(308.797 373.995)">{"OPS\u0130YON"}</text>
    </g>
  </svg>
)
export default Svgyuzbireyirmiiki;
