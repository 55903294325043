import './App.css';
import {Routes,Route} from 'react-router-dom';
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import Contact from './components/Contact';
import Projects from './components/Projects';
import WrongPaht from './components/WrongPaht';
// import Header from './components/Header';
import Emlak from './components/Emlak';
import Arsa from './components/Arsa';
import Insaat from './components/Insaat';
import AllProjects from './components/AllProjects';
import Footer from './components/Footer';
import Team from './components/Team';
import History from './components/History';
import Mission from './components/Mission';
import PermenetQuestion from './components/PermenetQuestion';
import Offers from './components/Offers';
import FooterUp from './components/FooterUp';
import Blog from './components/Blog';
import PlanlananProjecks from './components/PlanlananProjecks';
import Byuzdorteiki from './parseller/Byuzdorteiki';
import Byuzaltiyauc from './parseller/Byuzaltiyauc';
import Byuzbesebir from './parseller/Byuzbesebir';
import Byuzkirkbeseonalti from './parseller/Byuzkirkbeseonalti';
import Byuzellibeseyedi from './parseller/Byuzellibeseyedi';
import Byuzelliucebir from './parseller/Byuzelliucebir';
import Byuzbireyirmiiki from './parseller/Byuzbireyirmiiki';
import Byuzellibireotuziki from './parseller/Byuzellibireotuziki';
import Byuzonbeseonuc from './parseller/Byuzonbeseonuc';
import ByuzellibireOnaltı from './parseller/ByuzellibireOnaltı';
import ByuzkirkdokuzaBes from './parseller/ByuzkirkdokuzaBes';
import Byuzbireelliuc from './parseller/Byuzbireelliuc';
import ByuzellibireDortBes from './parseller/ByuzellibireDortBes';
import ByuzyediyeBir from './parseller/ByuzyediyeBir';
import ByuzkirkdorteDort from './parseller/ByuzkirkdorteDort';
import ByuzellibeseOnuc from './parseller/ByuzellibeseOnuc';
import Navbar from './nvbr/Navbar.jsx';
import ByuzyirmiyeUc from './parseller/ByuzyirmiyeUc.jsx';




function App({ Component, pageProps }) {
  return (
    <div className="App">
      <Navbar/>
      {/* <Header/> */}
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/projeler' element={<Projects/>}>
        </Route>
        <Route path='/hakkımızda' element={<AboutUs/>}>
          <Route path='ekibimiz' element={<Team/>}/>
          <Route path='tarihimiz' element={<History/>}/>
          <Route path='hedefimiz' element={<Mission/>}/>
        </Route>
        <Route path='/sıksorulansorular' element={<PermenetQuestion/>}/>
        <Route path='/hizmetler' element={<Offers/>}/>
        <Route path='/blog' element={<Blog/>}/>
        <Route path='/iletisim' element={<Contact/>}/>
        <Route path='*' element={<WrongPaht/>}/>

        <Route path='/projeler/emlak' element={<Emlak/>}/>
        <Route path='/projeler/arsa' element={<Arsa/>}/>
        <Route path='/projeler/inşaat' element={<Insaat/>}/>
        <Route path='/projeler/planlananprojeler' element={<PlanlananProjecks/>}/>
        <Route path='/projeler/tamamlananprojeler' element={<AllProjects/>}/>
        <Route path='/projeler/tumprojeler' element={<Projects/>}/>
        
        <Route path='/projeler/emlak/belgrat-yüzdörte-iki' element={<Byuzdorteiki/>}/>
        <Route path='/projeler/emlak/belgrat-yüzaltıya-üç' element={<Byuzaltiyauc/>}/>
        <Route path='/projeler/emlak/belgrat-yüzbeşe-bir' element={<Byuzbesebir/>}/>
        <Route path='/projeler/emlak/belgrat-yüzkırkbeşe-onaltı' element={<Byuzkirkbeseonalti/>}/>
        <Route path='/projeler/emlak/belgrat-yüzellibeşe-yedi' element={<Byuzellibeseyedi/>}/>
        <Route path='/projeler/emlak/belgrat-yüzelliüçe-bir' element={<Byuzelliucebir/>}/>
        <Route path='/projeler/emlak/belgrat-yüzbire-yirmiiki' element={<Byuzbireyirmiiki/>}/>
        <Route path='/projeler/emlak/belgrat-yüzellibire-otuziki' element={<Byuzellibireotuziki/>}/>
        <Route path='/projeler/emlak/belgrat-yüzonbeşe-onüç' element={<Byuzonbeseonuc/>}/>
        <Route path='/projeler/planlananprojeler/belgrat-yüzellibire-onaltı-yirmi-kırkdört' element={<ByuzellibireOnaltı/>}/>
        <Route path='/projeler/belgrat-yüzkırkdokuza-beş' element={<ByuzkirkdokuzaBes/>}/>
        <Route path='/projeler/belgrat-yüzbire-elliüç' element={<Byuzbireelliuc/>}/>
        <Route path='/projeler/belgrat-yüzellibire-dort-beş' element={<ByuzellibireDortBes/>}/>
        <Route path='/projeler/belgrat-yüzyediye-bir' element={<ByuzyediyeBir/>}/>
        <Route path='/projeler/belgrat-yüzkırkdörte-dört' element={<ByuzkirkdorteDort/>}/>
        <Route path='/projeler/belgrat-yüzellibeşe-onüç' element={<ByuzellibeseOnuc/>}/>
        <Route path='/projeler/belgrat-yüzyirmiye-üç' element={<ByuzyirmiyeUc/>}/>
      </Routes>
      <FooterUp/>
      <Footer/>
    </div>
    
  );
}

export default App;


