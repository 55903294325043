import * as React from "react"
const Svgyuzyediyebir = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 174 373",
    }}
    viewBox="0 0 174 373"
    {...props}
  >
    <style>
      {
        '.st4{fill:#1d1d1b;stroke:#1d1d1b;stroke-width:.25;stroke-miterlimit:10}.st5{font-family:"MyriadPro-Regular"}.st6{font-size:6.4152px}.st9{fill:#fbb03b;stroke:#000;stroke-miterlimit:10}.st10{font-size:6px}'
      }
    </style>
    <g id="katman_4">
      <path
        d="M14.5 40.6h8v8h-8z"
        style={{
          fill: "#fbb03b",
          stroke: "#fbb03b",
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M14.5 56.1h8v8h-8z"
        style={{
          fill: "#0f0",
          stroke: "#0f0",
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M14.5 71.6h8v8h-8z"
        style={{
          fill: "#53a2da",
          stroke: "#53a2da",
          strokeMiterlimit: 10,
        }}
      />
      <text className="st4 st5 st6" transform="translate(27.793 47.759)">
        {"SATILDI"}
      </text>
      <text className="st4 st5 st6" transform="translate(28.643 64.065)">
        {"SATI\u015ETA"}
      </text>
      <text className="st4 st5 st6" transform="translate(28.074 79.033)">
        {"OPS\u0130YON"}
      </text>
    </g>
    <g id="katman_3">
      <path
        d="m53.5 344.5 9-25 7-22 7-22 7-22 8-23 8-27 9-24 8-25 7-24 7.8-22.4 8.5 3.5-15.3 45.9-9 27-7 21-5 16-4 14-6 16-7 21-8 25-5 16-4 11-4 12-3 11z"
        style={{
          fill: "#ccc",
          stroke: "#ccc",
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="m135.8 109.7-31.3 94.8-9 28-37.6 113.8"
        style={{
          fill: "none",
          stroke: "#e6e6e6",
          strokeWidth: 0.5,
          strokeMiterlimit: 10,
          strokeDasharray: 5,
        }}
      />
    </g>
    <g id="katman_2">
      <path d="m133.5 6.5 28 5-3 17-28-11z" className="st9" />
      <path d="m130.5 17.5-6 24-6 10 31 12 4-23 5-12z" className="st9" />
      <path d="m118.5 51.5-14 24 44 17 1-29z" className="st9" />
      <path
        d="m104.5 75.5-5 9-4 10 53 20v-22zM148.5 114.5l1 15 3 39-28-11 15.3-45.9z"
        className="st9"
      />
      <path d="m124.5 157.5-9 27 34 13 3-21v-8z" className="st9" />
      <path d="m115.5 184.5-7 21 48 18-7-22v-4z" className="st9" />
      <path d="m108.5 205.5-5 16 58 22v-5l-5-15z" className="st9" />
      <path d="m103.5 221.5-4 14 58 23 3-3 1-12z" className="st9" />
      <path d="m99.5 235.5-6 16 47 18 17-11z" className="st9" />
      <path d="m93.5 251.5-7 21 36 13 14-13 4-3z" className="st9" />
      <path d="m86.5 272.5-8 25 36 14-1-3 2-12 7-11z" className="st9" />
      <path
        d="m78.5 297.5-5 16 75 29-32-26-2-5zM73.5 313.5l-4 11 74 28 7-9z"
        className="st9"
      />
      <path d="m69.5 324.5-4 12 69 27 9-11z" className="st9" />
      <path
        d="m65.5 336.5-3 11 46 22 20 1 6-7zM95.5 94.5l-6 14-2 8 36 14 7.8-22.4z"
        className="st9"
      />
      <path d="m123.5 130.5-7 24-36-14 7-24z" className="st9" />
      <path d="m80.5 140.5-6 25 34 14 8-25z" className="st9" />
      <path d="m74.5 165.5-4 12-8 11 37 15 9-24z" className="st9" />
      <path d="m62.5 188.5-5 6-11 18 45 18 8-27z" className="st9" />
      <path d="m46.5 212.5-11 16-2 6 50 19 8-23z" className="st9" />
      <path d="m33.5 234.5-8 22 51 19 7-22z" className="st9" />
      <path d="m25.5 256.5-8 21 52 20 7-22z" className="st9" />
      <path d="m17.5 277.5-8 21 53 21 7-22z" className="st9" />
      <path d="m62.5 319.5-9 25-29-14-21-17 6-15z" className="st9" />
    </g>
    <g id="katman_5">
      <g id="P01_00000109024594303770744930000014150199222647489977_">
        <text
          id="P03"
          className="st5 st10"
          transform="rotate(21.14 21.521 368.229)"
        >
          {"122.45 m\xB2"}
        </text>
        <text
          id="P01_00000118369468460608477820000011855959991097000064_"
          className="st5 st10"
          transform="rotate(21.14 36.368 372.236)"
        >
          {"P01"}
        </text>
      </g>
      <g id="P03">
        <text
          id="P01_00000052825009845248152090000001527293819313926048_"
          className="st5 st10"
          transform="rotate(20.586 -46.736 369.196)"
        >
          {"244.89 m\xB2"}
        </text>
        <text
          id="P01_00000166655421091834912190000003176240755978745276_"
          className="st5 st10"
          transform="rotate(20.586 -31.47 373.236)"
        >
          {"P02"}
        </text>
      </g>
      <g id="P03">
        <text
          id="P01_00000067208517876601514520000006396567855628039306_"
          className="st5 st10"
          transform="rotate(20.348 -142.07 367.274)"
        >
          {"244.89 m\xB2"}
        </text>
        <text
          id="P01_00000150814874150566874160000017665072593713341314_"
          className="st5 st10"
          transform="rotate(20.348 -126.62 371.328)"
        >
          {"P03"}
        </text>
      </g>
      <g id="P04">
        <text
          id="P01_00000003072228773036833600000013503022906245508533_"
          className="st5 st10"
          transform="rotate(19.3 -220.073 378.976)"
        >
          {"244.89 m\xB2"}
        </text>
        <text
          id="P01_00000145742144285769147190000006663757664304859570_"
          className="st5 st10"
          transform="rotate(19.3 -203.754 383.099)"
        >
          {"P04"}
        </text>
      </g>
      <g id="P05">
        <text
          id="P01_00000003106827243196943770000012599775422210602626_"
          className="st5 st10"
          transform="rotate(20.8 -363.869 421.092)"
        >
          {"214.28 m\xB2"}
        </text>
        <text
          id="P01_00000146465486129194945640000010552486023009872260_"
          className="st5 st10"
          transform="rotate(20.8 -348.767 425.12)"
        >
          {"P05"}
        </text>
      </g>
      <g id="P06">
        <text
          id="P01_00000053526571758917273370000002827459498044828343_"
          className="st5 st10"
          transform="rotate(20.8 -421.264 412.701)"
        >
          {"214.28 m\xB2"}
        </text>
        <text
          id="P01_00000086679712557292790090000007205117683270696359_"
          className="st5 st10"
          transform="rotate(20.8 -406.162 416.73)"
        >
          {"P06"}
        </text>
      </g>
      <g id="P07">
        <text
          id="P01_00000032622101127525499620000014079549510811839147_"
          className="st5 st10"
          transform="rotate(20.8 -491.57 405.892)"
        >
          {"214.28 m\xB2"}
        </text>
        <text
          id="P01_00000122000286927002991810000005197945777517605543_"
          className="st5 st10"
          transform="rotate(20.8 -476.468 409.92)"
        >
          {"P07"}
        </text>
      </g>
      <g id="P08">
        <text
          id="P01_00000085939148115808742310000014075696072954404251_"
          className="st5 st10"
          transform="rotate(20.8 -544.437 398.699)"
        >
          {"214.28 m\xB2"}
        </text>
        <text
          id="P01_00000150783460763814125420000017088512883331173047_"
          className="st5 st10"
          transform="rotate(20.8 -529.335 402.727)"
        >
          {"P08"}
        </text>
      </g>
      <g id="P09">
        <text
          id="P01_00000116942685196676671690000015748297869498502572_"
          className="st5 st10"
          transform="rotate(20.8 -585.644 394.999)"
        >
          {"214.28 m\xB2"}
        </text>
        <text
          id="P01_00000025432619044849325470000017763587196358741695_"
          className="st5 st10"
          transform="rotate(20.8 -570.543 399.026)"
        >
          {"P09"}
        </text>
      </g>
      <g id="P10">
        <text
          id="P01_00000136409639862995155430000002368663673676261251_"
          className="st5 st10"
          transform="rotate(20.8 -629.838 389.03)"
        >
          {"214.28 m\xB2"}
        </text>
        <text
          id="P01_00000034049147031476047410000016736901095581803414_"
          className="st5 st10"
          transform="rotate(20.8 -614.737 393.057)"
        >
          {"P10"}
        </text>
      </g>
      <g id="P11">
        <text
          id="P01_00000096752291845133899180000005484212140479021736_"
          className="st5 st10"
          transform="rotate(20.8 -682.588 381.814)"
        >
          {"214.28 m\xB2"}
        </text>
        <text
          id="P01_00000145046079895263643030000002006989996606186123_"
          className="st5 st10"
          transform="rotate(20.8 -667.486 385.842)"
        >
          {"P11"}
        </text>
      </g>
      <g id="P12">
        <text
          id="P01_00000042734715223775009340000015386757449907254970_"
          className="st5 st10"
          transform="rotate(20.8 -750.077 371.672)"
        >
          {"214.28 m\xB2"}
        </text>
        <text
          id="P01_00000071550651896066912660000004709329311224347581_"
          className="st5 st10"
          transform="rotate(20.8 -734.975 375.7)"
        >
          {"P12"}
        </text>
      </g>
      <g id="P13">
        <text
          id="P01_00000052822745569752750630000009758429337746841255_"
          className="st5 st10"
          transform="rotate(20.8 -808.064 362.602)"
        >
          {"214.28 m\xB2"}
        </text>
        <text
          id="P01_00000180360775478268445870000001726812797596986301_"
          className="st5 st10"
          transform="rotate(20.8 -792.962 366.63)"
        >
          {"P13"}
        </text>
      </g>
      <g id="P14">
        <text
          id="P01_00000143579916975947705680000002879281104095154823_"
          className="st5 st10"
          transform="rotate(20.8 -847.257 358.53)"
        >
          {"214.28 m\xB2"}
        </text>
        <text
          id="P01_00000070094809618709104870000011106806726011019949_"
          className="st5 st10"
          transform="rotate(20.8 -832.155 362.558)"
        >
          {"P14"}
        </text>
      </g>
      <g id="P15">
        <text
          id="P01_00000177453844599540246170000001584304707495223194_"
          className="st5 st10"
          transform="rotate(20.8 -881.949 353.633)"
        >
          {"214.28 m\xB2"}
        </text>
        <text
          id="P01_00000056399507469427248540000006834752393170942106_"
          className="st5 st10"
          transform="rotate(20.8 -866.847 357.661)"
        >
          {"P15"}
        </text>
      </g>
      <g id="P16">
        <text
          id="P01_00000106127854138915415900000017406031510461586103_"
          className="st5 st10"
          transform="rotate(20.8 -913.917 348.236)"
        >
          {"214.28 m\xB2"}
        </text>
        <text
          id="P01_00000095297181963624329610000012455548864196830897_"
          className="st5 st10"
          transform="rotate(20.8 -898.815 352.264)"
        >
          {"P16"}
        </text>
      </g>
      <g id="P17">
        <text
          id="P01_00000143603115696006843360000018038232038048961665_"
          className="st5 st10"
          transform="rotate(20.8 -868.359 247.556)"
        >
          {"285.71 m\xB2"}
        </text>
        <text
          id="P01_00000112624809841682869900000007456972833478215828_"
          className="st5 st10"
          transform="rotate(20.8 -853.257 251.584)"
        >
          {"P17"}
        </text>
      </g>
      <g id="P18">
        <text
          id="P01_00000144332928590465309290000016967726237926911668_"
          className="st5 st10"
          transform="rotate(20.8 -804.423 258.35)"
        >
          {"285.71 m\xB2"}
        </text>
        <text
          id="P01_00000091719817771738574550000012598177507841304252_"
          className="st5 st10"
          transform="rotate(20.8 -789.322 262.378)"
        >
          {"P18"}
        </text>
      </g>
      <g id="P19">
        <text
          id="P01_00000049910308471290998610000007332971122497936809_"
          className="st5 st10"
          transform="rotate(20.8 -741.79 269.384)"
        >
          {"285.71 m\xB2"}
        </text>
        <text
          id="P01_00000119795867997234912660000015462812576473715089_"
          className="st5 st10"
          transform="rotate(20.8 -726.69 273.412)"
        >
          {"P19"}
        </text>
      </g>
      <g id="P20">
        <text
          id="P01_00000067195446127212565420000009906104612299319716_"
          className="st5 st10"
          transform="rotate(20.8 -676.46 277.106)"
        >
          {"285.71 m\xB2"}
        </text>
        <text
          id="P01_00000060003332803297577660000003644922376643145378_"
          className="st5 st10"
          transform="rotate(20.8 -661.359 281.134)"
        >
          {"P20"}
        </text>
      </g>
      <g id="P21">
        <text
          id="P01_00000074435171315020768920000014983415704287831443_"
          className="st5 st10"
          transform="rotate(20.8 -612.406 287.879)"
        >
          {"285.71 m\xB2"}
        </text>
        <text
          id="P01_00000155839067660679894930000014341065857065748671_"
          className="st5 st10"
          transform="rotate(20.8 -597.305 291.907)"
        >
          {"P21"}
        </text>
      </g>
      <g id="P22">
        <text
          id="P01_00000032622108304999687150000011976608707128684425_"
          className="st5 st10"
          transform="rotate(20.8 -538.666 294.057)"
        >
          {"285.71 m\xB2"}
        </text>
        <text
          id="P01_00000034080643230619584690000012886827516891280806_"
          className="st5 st10"
          transform="rotate(20.8 -523.564 298.085)"
        >
          {"P22"}
        </text>
      </g>
      <g id="P23_00000075146066330295999660000017108549765219087804_">
        <text
          id="P01_00000155837199033149719100000011390152912184833432_"
          className="st5 st10"
          transform="rotate(20.8 -463.214 305.554)"
        >
          {"214.28 m\xB2"}
        </text>
        <text
          id="P01_00000053547227535817466980000000361083746937055677_"
          className="st5 st10"
          transform="rotate(20.8 -448.112 309.582)"
        >
          {"P23"}
        </text>
      </g>
      <g id="P24_00000163794872180562742180000009525676864150661762_">
        <text
          id="P01_00000073708622300711329200000001890934109304727219_"
          className="st5 st10"
          transform="rotate(20.8 -393.567 318.116)"
        >
          {"214.28 m\xB2"}
        </text>
        <text
          id="P01_00000182527787165087165660000010701752149987387839_"
          className="st5 st10"
          transform="rotate(20.8 -378.465 322.144)"
        >
          {"P24"}
        </text>
      </g>
      <g id="P25_00000158729622474310753080000008106578882662372001_">
        <text
          id="P01_00000072239416457862286480000001063780463181320087_"
          className="st5 st10"
          transform="rotate(20.8 -322.432 324.773)"
        >
          {"214.28 m\xB2"}
        </text>
        <text
          id="P01_00000047049293308538767510000002584959419705072014_"
          className="st5 st10"
          transform="rotate(20.8 -307.33 328.802)"
        >
          {"P25"}
        </text>
      </g>
      <g id="P26">
        <text
          id="P01_00000032643664995362906920000014304288058612149139_"
          className="st5 st10"
          transform="rotate(20.8 -255.773 335.068)"
        >
          {"214.28 m\xB2"}
        </text>
        <text
          id="P01_00000142894856037176851160000007365530602009021346_"
          className="st5 st10"
          transform="rotate(20.8 -240.67 339.096)"
        >
          {"P26"}
        </text>
      </g>
    </g>
  </svg>
)
export default Svgyuzyediyebir;
