import React, {useState} from 'react';
import '../nvbr/Navbar.css';
import Dropdown from './Dropdown';
import DropdownIki from './DropdownIki';

function Navbar () {
    const [click, setClick] = useState(false);
    const [dropdown, setDropdown] = useState(false);
    const [dropdowniki, setDropdowniki] = useState(false);

    const handleClick = () => setClick (!click);
    const closeMobileMenu = () => setClick (false);

    const onMouseEnter = () => {
        if (window.innerWidth < 600) {
            setDropdown(false);
        }else {
            setDropdown(true);
        }
    };
    
    const onMouseLeave = () => {
        if (window.innerWidth < 600) {
            setDropdown(false);
        }else {
            setDropdown(false);
        }
    };
        // <i className='fab fa-firstdraft'/>

        const onMouseEnterIki = () => {
            if (window.innerWidth < 600) {
                setDropdowniki(false);
            }else {
                setDropdowniki(true);
            }
        };
        
        const onMouseLeaveIki = () => {
            if (window.innerWidth < 600) {
                setDropdowniki(false);
            }else {
                setDropdowniki(false);
            }
        };
    return (
        <div className='ali_metin'>
            <nav className="navbar" style={{fixed:'top'}}>
                <a href='/' className="navbar-logo">
                    EMLAK<span className='logoCompany'>ÇIN</span>
                </a>
            </nav>
            <div className="menu-icon" onClick={handleClick}>
                <i className={click ? 'fas fa-times' : 'fas fa-bars'}/>
            </div>
            <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                <li className="nav-item">
                    <a href="/" className="nav-links" onClick=
                    {closeMobileMenu}>       
                    Anasayfa
                    </a>
                </li>
                <li className="nav-item"
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    >
                    <a href="/projeler" className="nav-links" onClick={closeMobileMenu}>
                    Projeler <i className="fas fa-caret-down"/>
                    </a>
                    {dropdown && <Dropdown/>}
                </li>
                <li className="nav-item"
                    onMouseEnter={onMouseEnterIki}
                    onMouseLeave={onMouseLeaveIki}
                    >
                    <a href="/hakkımızda" className="nav-links" onClick={closeMobileMenu}>
                    Hakkımızda <i className="fas fa-caret-down"/>
                    </a>
                    {dropdowniki && <DropdownIki/>}
                </li>
                <li className="nav-item">
                    <a href="/sıksorulansorular" className="nav-links" onClick=
                    {closeMobileMenu}>       
                    Sorularınız
                    </a>
                </li>
                <li className="nav-item">
                    <a href="/blog" className="nav-links" onClick=
                    {closeMobileMenu}>       
                    Blog
                    </a>
                </li>
                <li className="nav-item">
                    <a href="/iletisim" className="nav-links" onClick=
                    {closeMobileMenu}>       
                    İletişim
                    </a>
                </li>
            </ul>
        </div>
    )
}

export default Navbar;