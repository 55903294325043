import * as React from "react"
const Svgyuzaltiyauc = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 595.3 841.9",
      width:'500px'
    }}
    viewBox="0 0 595.3 841.9"
    {...props}
  >
    <style>
      {
        '.st0{display:none}.st3{font-family:"MyriadPro-Regular"}.st4{font-size:12px}.st5,.st6{fill:#b2b2b2;stroke:#b2b2b2;stroke-miterlimit:10}.st6{fill:none;stroke:#000}.st7{fill:none;stroke:#fff;stroke-width:.5}.st13,.st7,.st8{stroke-miterlimit:10}.st8{fill:none;stroke:#fff;stroke-width:.5;stroke-dasharray:11.5632,11.5632}.st13{stroke:#000;stroke-width:.25}.st14{font-size:7.92px}.st15,.st16{fill:#66b32e;stroke:#000;stroke-miterlimit:10}.st16{fill:#f39200}.st17{font-size:7px}.st18{font-size:8px}'
      }
    </style>
    <g>
      <path d="m215.5 713.8 25.1-19.1 53.2-5.6 9.4-1 147.5 18.4 86.2 10.5 24-2.5v54.3l-47.3 6.2-134.3-20.8-29.9-.4-68.1-9.4-31 32.4z"
        style={{
          fill: "#71bfec",
          stroke: "#71bfec",
          strokeMiterlimit: 10,
        }}
      />
      <text transform="translate(390.802 730.371)">{"DERE"}</text>
    </g>
    <g>
      <path style={{fill: '#c6c6c6'}} d="m59.3 261 40-27.5 45.3-26.8 14.6-9.4 82.4-52.5 14.7-9.7 53.7-34.4 29.3-22-13.9-16.4-96 60.2-40.3 26.5-40.4 25-28.1 18.8L83 216.1l-23.7 15.3z"/>
      <path style={{fill: '#c6c6c6'}} d="m144.6 206.7 14.6-9.4 26.1 81.7 11.1 36.6L210 359l25.3 82.8 16 50.1 50.8 165.6 18.1 14.9 85.2 11.2 14.3-17.4L349.4 462l-17-49.7-28.2-81.8-15.3-44.2-12.2-34.7-35.1-106.8 14.7-9.7L298 257.5l82.7 242.8 39 114 31 92.2-147.5-18.4-9.4 1-36.5-120.3-12.9-39-12.9-42.1-12.8-41.7-13.6-42.8-26.4-85.2-34.1-111.3"/>
      <path d="m59.3 231.4 266.1-169-266.1 169zM59.3 261l40-27.5M310 100.7l29.3-22" className="st6"/>
      <path d="m63.9 242.7-5.1 3.3 5.1-3.3" className="st7" />
      <path d="M73.6 236.5 322.3 78" className="st8" />
      <path d="m327.2 74.9 5-3.2-5 3.2" className="st7" />
      <path d="M317.4 81.1 68.8 239.6" className="st8" />
      <path
        d="M149.1 196.4 291 654.5l1.6 7.3 25 20.4 91.8 11.5 20.9-24-184.7-538"
        style={{
          fill: "none",
          stroke: "#fff",
          strokeWidth: 0.5,
          strokeMiterlimit: 10,
          strokeDasharray: 12,
        }}
      />
    </g>
    <g id="legn">
      <path
        d="M458.2 193.6h9.9v9.9h-9.9z"
        style={{
          fill: "#f0804d",
          stroke: "#f0804d",
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M458.2 212.8h9.9v9.9h-9.9z"
        style={{
          fill: "#00FF00",
          stroke: "#00FF00",
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M458.2 231.9h9.9v9.9h-9.9z"
        style={{
          fill: "#54a4db",
          stroke: "#54a4db",
          strokeMiterlimit: 10,
        }}
      />
      <text id="legantText" transform="translate(474.645 202.523)">{"SATILDI"}</text>
      <text id="legantText" transform="translate(475.695 222.655)">{"SATI\u015ETA"}</text>
      <text id="legantText" transform="translate(474.993 241.135)">{"OPS\u0130YON"}</text>
    </g>
    <g>
      <path id="katman_4" d="m256.3 135.1 53.7-34.4 50.4 135.4-62.4 21.4z"/>
      <path id="katman_4" d="m298 257.5 62.4-21.4L448.6 477l-67.9 23.3z"/>
      <path id="katman_2" d="m380.7 500.3 67.9-23.3L490 589.7l-70.3 24.6z"/>
      <path id="katman_2" d="m419.7 614.3 70.3-24.6L536.9 717l-86.2-10.5zM251.3 491.9l98.1-29.9 70.3 204.2-14.3 17.4-85.2-11.2-18.1-14.9zM251.3 491.9l-16-50.1 97.1-29.5 17 49.7z"/>
      <path id="katman_2" d="M235.3 441.8 210 359l94.2-28.5 28.2 81.8z"/>
      <path id="katman_2" d="m210 359-13.6-43.4 92.5-29.3 15.3 44.2z"/>
      <path id="katman_2" d="M196.4 315.6 185.3 279l91.4-27.4 12.2 34.7z"/>
      <path id="katman_2" d="m185.3 279-26.1-81.7 82.4-52.5 35.1 106.8zM99.3 233.5l45.3-26.8L178.7 318l-48.4 13.9zM130.3 331.9l48.4-13.9 26.4 85.2-49.1 14.6z"/>
      <path id="katman_2" d="m156 417.8 49.1-14.6 13.6 42.8-49.1 15z" className="st16" />
      <path id="katman_2" d="m169.6 461 49.1-15 12.8 41.7-49.7 16zM181.8 503.7l49.7-16 12.9 42.1-49.4 15.7z"/>
      <path id="katman_2" d="m195 545.5 49.4-15.7 12.9 39-50.5 14.9zM206.8 583.7l50.5-14.9 36.5 120.3-53.2 5.6z"/>
      <path id="katman_2" d="m293.8 689.1 9.4-1 147.5 18.4"/>
    </g>
    <g>
      <text id="P01" transform="matrix(1.1171 0 0 1 283.296 183.785)">{"P01-543.47 m\xB2"}</text> 
      <text id="P01" transform="matrix(1.1171 0 0 1 338.846 354.66)">{"P02-1,086.93 m\xB2"}</text>
      <text id="P01" transform="matrix(1.1171 0 0 1 409.619 546.305)">{"P03-543.47 m\xB2"}</text>
      <text id="P01" transform="matrix(1.1171 0 0 1 452.442 657.75)">{"P04-543.47 m\xB2"}</text>
      <text id="P01" transform="matrix(1.1171 0 0 1 302.43 574.863)">{"P05-1,396.41 m\xB2"}</text>
      <text id="P01" transform="matrix(1.1171 0 0 1 265.575 455.23)">{"P06-301.93 m\xB2"}</text>
      <text id="P01" transform="matrix(1.1171 0 0 1 244.244 390.92)">{"P07-534.66 m\xB2"}</text>
      <text id="P01" transform="matrix(1.1171 0 0 1 224.021 324.964)">{"P08-279.91 m\xB2"}</text>
      <text id="P01" transform="matrix(1.1171 0 0 1 215.474 288.79)">{"P09-225.40 m\xB2"}</text>
      <text id="P01" transform="matrix(1.1171 0 0 1 189.882 221.484)">{"P10-582.89 m\xB2"}</text>
      <text id="P01" transform="matrix(1.142 0 0 1 115.714 275.59)">{"P11-341.67 m\xB2"}</text>
      <text id="P01" transform="matrix(1.142 0 0 1 145.405 371.63)">{"P12-282.72 m\xB2"}</text>
      <text id="P01" transform="matrix(1.142 0 0 1 165.467 435.82)">{"P13-141.36 m\xB2"}</text>
      <text id="P01" transform="matrix(1.142 0 0 1 179.666 477.45)">{"P14-141.36 m\xB2"}</text>
      <text id="P01" transform="matrix(1.142 0 0 1 190.696 519.175)">{"P15-141.36 m\xB2"}</text>
      <text id="P01" transform="matrix(1.142 0 0 1 204.331 558.731)">{"P16-129.51 m\xB2"}</text>
      <text id="P01" transform="matrix(1.142 0 0 1 223.84 621.101)">{"P17-392.66 m\xB2"}</text>
    </g>
  </svg>
)
export default Svgyuzaltiyauc;
