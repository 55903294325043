import React from 'react';

const Svgyukirkbeseonalti = () => {
  return (
    <div>
        <svg width='300px' height='400px'>
            
            

            <style>
            {
                '.st4{fill:#1d1d1b;stroke:#1d1d1b;stroke-width:.25;stroke-miterlimit:10}.st5{font-family:"MyriadPro-Regular"}.st6{font-size:7.92px}.st7{fill:#b3b3b3}.st12,.st8,.st9{fill:none;stroke-miterlimit:10}.st8{stroke:#fff;stroke-dasharray:3}.st12,.st9{stroke:#000;stroke-width:.25}.st12{fill:#fff;stroke:#fff;stroke-width:.25}.st13{font-size:6px}'
            }
            </style>
            <g id="katman_4">
            <path
                d="M221.8 290.1h9.9v9.9h-9.9z"
                style={{
                fill: "#fbb03b",
                stroke: "#fbb03b",
                strokeMiterlimit: 10,
                }}
            />
            <path
                d="M221.8 309.2h9.9v9.9h-9.9z"
                style={{
                fill: "#0f0",
                stroke: "#0f0",
                strokeMiterlimit: 10,
                }}
            />
            <path
                d="M221.8 328.4h9.9v9.9h-9.9z"
                style={{
                fill: "#53a2da",
                stroke: "#53a2da",
                strokeMiterlimit: 10,
                }}
            />
            <text className="st4 st5 st6" transform="translate(238.255 298.968)">
                {"SATILDI"}
            </text>
            <text className="st4 st5 st6" transform="translate(239.306 319.1)">
                {"SATI\u015ETA"}
            </text>
            <text className="st4 st5 st6" transform="translate(238.603 337.58)">
                {"OPS\u0130YON"}
            </text>
            </g>
            <g id="katman_3">
                <path d="m229 54 12.5 2.5L154 360l-12-6z" className="st7" />
                <path d="m99 370 7-27 18 2 18 9 12 6 20 3-5 30-24-3-33-16z" className="st7"/>
                <path d="m102.6 354.8 17.9 3.7 28 16 23 2M146.5 357.5l88.9-302.7" className="st8"/>
            </g>
            
            <g><path d="m168.5 392.5-24-2-32-16-14-4" className="st9"></path></g>
            <g><path d="M106 342l18 2M241.5 56.5 154 360l19 3" className="st9"/></g>
            <g><path id="katman_4" d="m203.5 49.5 38 7 12-40-40-6z" /></g>
            <g><path id="katman_2" d="M203.5 49.5 229 54l-87 300-18-10z"/></g>
            
            <text id="P01" transform="rotate(-72.962 146.214 -125.594)" >{"P02-205.86 m\xB2"}</text>
            <text id="P01" transform="rotate(-74.081 223.26 -15.078)">{"P01-823.46 m\xB2"}</text>
        </svg>
    </div>
  )
}

export default Svgyukirkbeseonalti;