import { useState } from 'react';
import '../Style/Registration.css';
import React from 'react';
import { Grid,Typography } from '@mui/material'; 
import axios from 'axios';


const Registration = () => {
    const [nameSurname, setNamSurname] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [message, setMessage] = useState('');

    const handleChangeName = (value) => {
        setNamSurname(value)
    }
    const handleChangeEmail = (value) => {
        setEmail(value)
    }
    const handleChangePhoneNo = (value) => {
        setPhoneNo(value)
    }
    const handleChangeMessage = (value) => {
        setMessage(value)
    }

    const handleSave = () => {
        const data= {
            NameSurname:nameSurname,
            eMail:email,
            PhoneNo:phoneNo,
            Message:message
        };
        const url='https://localhost:44351/api/Test/Registration';
        axios.post(url,data).then((result) => {
           alert(result.data);
        }).catch((error) => {
            alert(error);
        })
    }

  return (
    <Grid className='mainGrid'>
        <Grid className='contactForm' >
            <Typography variant='h5' component='h5' sx={{marginBottom:3, color:'#3b4990'}}>İletişim Formu</Typography>
            <label>İsim Soyisim</label> 
            <input type='text' id='txtIsimSoyisim' name='Emlakçın İsim' placeholder='İsim ve Soyisminizi Giriniz!' onChange={(e) => handleChangeName(e.target.value)}/> <br></br>
            <label>Email</label> 
            <input type='email' id='txtEmail' name='Emlakçın Email' placeholder='Emailinizi Giriniz!' onChange={(e) => handleChangeEmail(e.target.value)}/> <br></br>
            <label>Telefon Numarası</label> 
            <input type='text' id='txtTelefonNumarasi' name='Emlakçın TelNo' placeholder='Telefon Numaranızı Giriniz!' onChange={(e) => handleChangePhoneNo(e.target.value)}/> <br></br>
            <label>Mesajınız</label> 
            <textarea type='text' id='txtMesaj' name='Emlakçın TextArea' placeholder='Mesajınızı Giriniz!' rows={5} onChange={(e) => handleChangeMessage(e.target.value)}/> <br></br>
            <button className='contactButton' onClick={() => handleSave()}>Gönder</button>
        </Grid>
    </Grid>
  )
}

export default Registration;