import {configureStore} from '@reduxjs/toolkit';
import cartReducer from '../control/cartSlice';
import dataReducer from '../control/dataSlice';


export const store = configureStore ({
    reducer:{
        cart:cartReducer,
        dataR:dataReducer,
    },
}); 