import React from 'react';
import {Outlet} from 'react-router-dom';

function AboutUs() {
  return (
    <>
        {/* <div>Hakkımızda</div> */}
        <Outlet/>
    </>

  )
}

export default AboutUs;