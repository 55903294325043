import React from 'react';
import '../Style/Contact.css';


import { Container} from '@mui/material';
import Registration from './Registration';

function Contact() {
  return (
    <Container className='contact_Main'>
      <Registration/>
    </Container>
    
  )
}

export default Contact;