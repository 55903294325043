import * as React from 'react';
import '../helpers/ProjectItems.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {useDispatch} from 'react-redux';
import { linkToGenel} from '../control/cartSlice';
import {useSelector} from 'react-redux';


function AllProjectItems({id,image,name,content}) {
  const dispatch = useDispatch();
  const handleClick = (e) => {
     dispatch(linkToGenel(id))
  }
  const {href} = useSelector ((store) => store.cart)
  return (
    <Card className='cardAllGeneral' sx={{ maxWidth: 345 }} >
      <CardMedia
        sx={{ height: 200 }}
        image={image}
        className='cardAllPhoto'
      />
      <CardContent className='cardAllName'>
        <Typography gutterBottom variant="h5" component="h5">
          {name}
        </Typography>
        <Typography className='cardAllContent' variant="body2">
          {content}
        </Typography>
      </CardContent>
      <CardActions>
        <a className='projetsLink' size="small" onClick={handleClick} href={href}>Projeler</a>
      </CardActions>
    </Card>
  );
}

export default AllProjectItems;