import * as React from "react"
const Svgyuzdortiki = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    
    viewBox="15 80 400 400"
    
  >
   
    <g id="katman_2">
      <path id="katman_2" d="m105.8 146.7 24-11.7 21 35.6-38 33.2z"/>
      <path id="katman_4"  d="m150.8 170.6 13.3 21.3-48.2 40.6-3.1-28.7z"/>
      <path id="katman_2"  d="m164.1 191.9 10.3 16.8-55.9 48-2.6-24.2z"/>
      <path id="katman_2"  d="m174.4 208.7 26.7 43.3-1.2 7.4-25.6 21.4-28.6-47.5zM126 322.4l-7.5-65.7 27.2-23.4 28.6 47.5zM215.9 258.1l10.5 15.8 13.3 18.3-23.8 20.2-26-30.8z"/>
      <path id="katman_2"  d="m159 308.7 30.9-27.1 26 30.7-30.8 27.1z"/>
      <path id="katman_2"  d="m129 334.3 30-25.6 26.1 30.7-16.1 14zM263.6 325.9l-54.4 46.8-40.2-19.3 70.4-60.8z"/>
      <path id="katman_2"  d="m282.6 352.1-41.8 35.7-31.6-15.1 54.4-46.8z"/>
      <path id="katman_2"  d="m282.6 352.1 62.9 86.7-104.7-51z"/>
      
    </g>
    <g id="katman_3">
      <path
        d="M151.9 388.8h8.2v8.2h-8.2z"
        style={{
          fill: "#ff8247",
          stroke: "#ff8247",
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M151.9 404.8h8.2v8.2h-8.2z"
        style={{
          fill: "#0f0",
          stroke: "#0f0",
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M151.8 420.7h8.2v8.2h-8.2z"
        style={{
          fill: "#3fa9f5",
          stroke: "#3fa9f5",
          strokeMiterlimit: 10,
        }}
      />
      <text className="legantText" transform="translate(165.553 396.233)">
        {"SATILDI"}
      </text>
      <text className="legantText" transform="translate(166.429 413.01)">
        {"SATI\u015ETA"}
      </text>
      <text className="legantText" transform="translate(165.843 428.409)">
        {"OPS\u0130YON"}
      </text>
    </g>
    
    <g id="P01"><text transform="matrix(.8042 -.085 .1051 .9945 263.81 394.487)">{"P01-477.87 "}</text></g>
    <g id="P01"><text transform="matrix(.9189 -.09712 .1051 .9945 232.582 362.43)">{"P02-332.97 "}</text></g>
    <g id="P01"><text transform="matrix(.8042 -.085 .1051 .9945 201.028 339.119)">{"P03-548.40 "}</text></g>
    <g id="P01"><text transform="matrix(.8042 -.085 .1051 .9945 196.282 287.625)">{"P04-213.62 "}</text></g>
    <g id="P01"><text transform="matrix(.8042 -.085 .1051 .9945 169.27 312.61)">{"P05-264.24 "}</text></g>          
    <g id="P01"><text transform="matrix(.8042 -.085 .1051 .9945 138.26 335.595)">  {"P06-202.44 "}</text></g>
    <g id="P01"><text transform="matrix(.8042 -.085 .1051 .9945 157.304 247.605)">{"P07-329.13 "}</text></g>
    <g id="P01"><text transform="matrix(.8297 -.0877 .1051 .9945 129.27 277.41)">{"P08-440.68 "}</text></g>
    <g id="P01"><text transform="matrix(.7071 -.4429 .5308 .8475 129.024 233.563)">{"P09-220.34 "}</text></g>
    <g id="P01"><text transform="matrix(.7071 -.4429 .5308 .8475 124.018 211.83)">{"P10-220.34 "}</text></g>
    <g id="P01"><text transform="matrix(.7071 -.4429 .5308 .8475 113.034 179.823)">{"P11-275.43 "}</text></g>
    <path id="katman_3" d="m129.8 135 9.9-5 76.8 127.7-26.6 23.9-30.9 27.1-30 25.6 40 19.1 40.2 19.3 33 15.8 99.4 48 3.9 2.3-10 7.1-215.4-103.3 1.1-15.8 80.9-70.9z"/>
  </svg>
)
export default Svgyuzdortiki;
