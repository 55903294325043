export const MenuItems =[
    {
        title:'Emlak',
        path:'/projeler/emlak',
        cName:'dropdown-link',
    },
    {
        title:'Arsa',
        path:'/projeler/arsa',
        cName:'dropdown-link',
    },
    {
        title:'İnşaat',
        path:'/projeler/inşaat',
        cName:'dropdown-link',
    },
    {
        title:'Planlanan Projeler',
        path:'/projeler/planlananprojeler',
        cName:'dropdown-link',
    },
    {
        title:'Tüm Projeler',
        path:'/projeler/tumprojeler',
        cName:'dropdown-link',
    },
]