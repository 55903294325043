import * as React from "react"
const SvgyuzonbeseOnuc = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 400 400",
    }}
    viewBox="0 0 400 400"
    {...props}
  >
    <style>
      {
        '.st1{fill:#ccc}.st2,.st3{fill:#ccc;stroke:#000;stroke-miterlimit:10}.st3{fill:none;stroke:#fff;stroke-width:.5;stroke-dasharray:12}.st4,.st5,.st9{fill:#fbb03b;stroke:#000;stroke-miterlimit:10}.st5,.st9{fill:#0f0}.st9{fill:#1d1d1b;stroke:#1d1d1b;stroke-width:.25}.st10{font-family:"MyriadPro-Regular"}.st11{font-size:5.94px}.st12{fill:#fff}.st13{font-size:5px}'
      }
    </style>
    <g id="katman__3">
      <path
        d="m191 389 41-2 16-2 22.3-7.8L289 368l11-3 50.5-15.5L388 342l5.5 39.5-90.2 18.5H191z"
        className="st1"
      />
      <path
        d="m88 32 34 56 23 40 15 25 17 28 20 34 25 40 17 31 15 23.9 13 22.1 22 36 11-3-70-118-50-83-32-54-49-83z"
        className="st1"
      />
      <path
        d="m232 387-41 2M350.5 349.5 388 342M303.3 400l64-13 26.2-5.5"
        className="st2"
      />
      <path
        d="m93.7 29.4 201.6 338.3M390.4 359.5l-87.1 22-39.1 18.5"
        className="st3"
      />
    </g>
    <g id="katman_2">
      <g id="katman_2"><path d="m232 387 16-2 23-8 18-9-22-36-48 30z"/></g>
      <g id="katman_2"><path d="m219 362-12-24 47-28.1 13 22.1z"/></g>
      <g id="katman_4"><path d="m207 338-13-26 45-26 15 23.9z"/></g>
      <g id="katman_4"><path d="m194 312 45-26-17-31-44 26z"/></g>
      <g id="katman_4"><path d="m178 281-22-42 41-24 25 40z"/></g>
      <g id="katman_4"><path d="m156 239-17-35 38-23 20 34z"/></g>
      <g id="katman_2"><path d="m139 204-15-29 36-22 17 28z"/></g>
      <g id="katman_2"><path d="m124 175-14-27 35-20 15 25z"/></g>
      <g id="katman_4"><path d="m110 148-20-41 32-19 23 40z"/></g>
      <g id="katman_4"><path d="M90 107 62 53l-4-8 30-13 34 56z"/></g>
      <g id="katman_2"><path d="m99 27 35-14 49 77-35 20z"/></g>
      <g id="katman_2"><path d="m148 110 35-20 33 53-36 21z"/></g>
      <g id="katman_2"><path d="m180 164 36-21 31 47 8 9 15 23-3 3-37 22z"/></g>
      <g id="katman_2"><path d="m230 247 70 118 51-16-16-30-30-43-38-51z"/></g>
    </g>
    <g id="katman_4">
      <path
        d="M107.1 335.3h7.4v7.4h-7.4z"
        style={{
          fill: "#fbb03b",
          stroke: "#fbb03b",
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M107.1 349.6h7.4v7.4h-7.4z"
        style={{
          fill: "#0f0",
          stroke: "#0f0",
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M107.1 364h7.4v7.4h-7.4z"
        style={{
          fill: "#53a2da",
          stroke: "#53a2da",
          strokeMiterlimit: 10,
        }}
      />
      <text className="st9 st10 st11" transform="translate(119.41 341.965)">
        {"SATILDI"}
      </text>
      <text className="st9 st10 st11" transform="translate(120.198 357.064)">
        {"SATI\u015ETA"}
      </text>
      <text className="st9 st10 st11" transform="translate(119.672 370.923)">
        {"OPS\u0130YON"}
      </text>
    </g>
    <g id="katman_5">
      <text
        id="P01"
        className="st12 st10 st13"
        transform="rotate(-31.851 669.214 -324.358)"
      >
        {"P01-1006.85 m\xB2"}
      </text>
      <text
        id="P02"
        className="st12 st10 st13"
        transform="rotate(-31.851 463.638 -270.18)"
      >
        {"P02-610.40 m\xB2"}
      </text>
      <text
        id="P03"
        className="st12 st10 st13"
        transform="rotate(-31.851 323.515 -231.58)"
      >
        {"P03-361.84 m\xB2"}
      </text>
      <text
        id="P04"
        className="st12 st10 st13"
        transform="rotate(-31.851 186.377 -194.071)"
      >
        {"P04-515.87 m\xB2"}
      </text>
      <text
        id="P05"
        className="st12 st10 st13"
        transform="rotate(-25.048 212.837 -138.067)"
      >
        {"P05-335.78 m\xB2"}
      </text>
      <text
        id="P06"
        className="st12 st10 st13"
        transform="rotate(-27.064 318.425 -157.38)"
      >
        {"P06-255.09 m\xB2"}
      </text>
      <text
        id="P07"
        className="st12 st10 st13"
        transform="rotate(-31.851 343.907 -137.752)"
      >
        {"P07-174.40 m\xB2"}
      </text>
      <text
        id="P08"
        className="st12 st10 st13"
        transform="rotate(-31.851 396.968 -151.037)"
      >
        {"P08-199.60 m\xB2"}
      </text>
      <text
        id="P09"
        className="st12 st10 st13"
        transform="rotate(-31.851 460.705 -165.065)"
      >
        {"P09-252.87 m\xB2"}
      </text>
      <text
        id="P10"
        className="st12 st10 st13"
        transform="rotate(-31.851 538.785 -182.582)"
      >
        {"P10-335.78 m\xB2"}
      </text>
      <text
        id="P11"
        className="st12 st10 st13"
        transform="rotate(-31.851 611.946 -199.606)"
      >
        {"P11-255.09 m\xB2"}
      </text>
      <text
        id="P12"
        className="st12 st10 st13"
        transform="rotate(-31.851 667.673 -212.13)"
      >
        {"P12-217.10 m\xB2"}
      </text>
      <text
        id="P13"
        className="st12 st10 st13"
        transform="rotate(-31.851 717.143 -222.649)"
      >
        {"P13-208.86 m\xB2"}
      </text>
      <text
        id="P14"
        className="st12 st10 st13"
        transform="rotate(-31.851 770.161 -234.051)"
      >
        {"P14-305.20 m\xB2"}
      </text>
    </g>
  </svg>
)
export default SvgyuzonbeseOnuc
