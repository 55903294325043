import React from 'react';
import '../Style/AboutUs.css';
import {Outlet} from 'react-router-dom';
import { Container,Typography } from '@mui/material';


function Mission() {
  return (
    <>
    <Container className='missionMain' style={{marginTop:95, marginBottom:20,color:'#3b4990'}}>
        <Typography variant='h4' component='h4' sx={{marginTop:1, marginBottom:1}}>Emlakçın: Yenilikçi ve Güvenilir Gayrimenkul Çözümleri</Typography>
        <Typography variant='h5' component='h5' sx={{marginTop:1, marginBottom:1}}>Vizyon ve Misyon</Typography>
        <Typography variant='body2' component='p'>
          Vizyonumuz, Türkiye'de ve uluslararası alanda gayrimenkul sektörünün en güvenilir ve yenilikçi firması olmaktır. Bu vizyon doğrultusunda, misyonumuz müşteri ihtiyaçlarını en iyi şekilde karşılamak, sürdürülebilir ve kaliteli gayrimenkul alanları oluşturmak ve yapılar inşa etmektir. Her projemizde ileri teknoloji kullanarak, estetik ve fonksiyonel projeler oluşturmayı hedefliyoruz.
        </Typography>
        <Typography variant='h5' component='h5' sx={{marginTop:1, marginBottom:1}}>Geniş Hizmet Yelpazesi</Typography>
        <Typography variant='body2' component='p'>
          Emlakçın, geniş hizmet yelpazesi ile her türlü gayrimenkul projesine çözüm sunmaktadır. Konut projelerinden, gayrimenkul projelerine kadar geniş bir yelpazede hizmet vermekteyiz. Müşterilerimize özel çözümler sunarak, projelerin her aşamasında profesyonel destek sağlıyoruz. Mühendislik, mimarlık, proje yönetimi ve danışmanlık hizmetlerimizle, müşterilerimizin hayalindeki projeleri gerçeğe dönüştürüyoruz.
        </Typography>
        <Typography variant='h5' component='h5' sx={{marginTop:1, marginBottom:1}}>Kalite ve Güvenlik</Typography>
        <Typography variant='body2' component='p'>
          Kalite, Emlakçın'ın vazgeçilmez değerlerinden biridir. Ulusal ve uluslararası kalite standartlarına uygun olarak, projelerimizde en kaliteli malzemeleri kullanmaktayız. Her aşamada titiz bir kalite kontrol süreci uygulayarak, inşa ettiğimiz yapıların dayanıklılığını ve estetiğini garanti altına alıyoruz. Aynı zamanda, iş sağlığı ve güvenliği konularında da en yüksek standartları benimsiyoruz. Çalışanlarımızın ve projelerimizin güvenliğini sağlamak için gerekli tüm önlemleri almaktayız.
        </Typography>
        <Typography variant='h5' component='h5' sx={{marginTop:1, marginBottom:1}}>Sürdürülebilirlik ve Çevre Duyarlılığı</Typography>
        <Typography variant='body2' component='p'>
          Sürdürülebilirlik, emlakçın için büyük bir öneme sahiptir. Çevreye duyarlı inşaat uygulamaları ve enerji verimliliği yüksek yapılarla, doğal kaynakların korunmasına katkıda bulunuyoruz. Yeşil bina sertifikaları ve çevre dostu malzemeler kullanarak, sürdürülebilir yapılar inşa etmekteyiz. Gelecek nesillere yaşanabilir bir dünya bırakmak amacıyla, her projede çevreye duyarlı bir yaklaşım sergilemekteyiz.
        </Typography>
        <Typography variant='h5' component='h5' sx={{marginTop:1, marginBottom:1}}>Müşteri Memnuniyeti</Typography>
        <Typography variant='body2' component='p'>
          Müşteri memnuniyeti, Emlakçın'ın en önemli önceliğidir. Müşterilerimizin beklentilerini karşılamak ve aşmak için, projelerin her aşamasında şeffaf ve açık bir iletişim sağlıyoruz. İhtiyaç ve beklentilere özel çözümler sunarak, her müşterimiz için en uygun projeyi hayata geçiriyoruz. Müşteri memnuniyeti bizim için bir sonuç değil, sürekli olarak geliştirdiğimiz bir süreçtir.
          <br/>
          Emalakçın, sektördeki deneyimi ve uzmanlığı ile güvenilir ve yenilikçi çözümler sunmaktadır. Modern ve sürdürülebilir yapılar inşa ederek, müşterilerimize en iyi hizmeti sunmayı taahhüt ediyoruz. Emlakçın ile çalışarak, hayalinizdeki projeyi gerçeğe dönüştürmek için ilk adımı atabilirsiniz. Müşterilerimizle olan güçlü bağlarımız ve proje bazlı işbirliklerimiz, bizi sektördeki rakiplerimizden ayıran en önemli özelliklerimizden biridir. Bizimle çalışmayı tercih eden her müşteri, en yüksek kalite ve profesyonellik seviyesinde hizmet almanın güvencesini yaşar.
        </Typography>
    </Container>
    <Outlet/>
    </>

  )
}

export default Mission;