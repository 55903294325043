import React, {useState} from 'react';
import '../parseller/./gallery.css';
import '../parseller/parselDetail.css';

import CloseIcon from '@mui/icons-material/Close';
import { Container,Box,Paper,Grid,Typography } from '@mui/material';
import Svgyuzellibiredortbes from '../components1/Svgyuzellibiredortbes';
import Registration from '../components/Registration';
import rsm1014 from '../imageproje/151-4-5/00.avif';
import img1 from '../imageproje/151-4-5/01.avif';
import img2  from '../imageproje/151-4-5/02.avif';
import img3  from '../imageproje/151-4-5/03.avif';
import img4  from '../imageproje/151-4-5/04.avif';
import img5  from '../imageproje/151-4-5/05.avif';
import img6  from '../imageproje/151-4-5/06.avif';

// import img7  from '../imageproje/105-1/05.jpg';
// import img8  from '../imageproje/105-1/08.png';
// import img9  from '../imageproje/105-1/06.jpg';



const ByuzellibireDortBes = () => {
    const [model, setModel] = useState(false);
    const [tempimgSrc, setTempimgSrc] = useState('');
    const getImg = (imgSrc) => {
        setTempimgSrc(imgSrc);
        setModel(true);
    }
  return (
    <>
        <div style={{width:'100%',marginTop:'83PX'}}>
            <p className='denemeTest'>BELGRAT MAHALLESİ KÖY YANI 1</p>
            <img src={rsm1014} alt="Belgrat Mahallesi" style={{width:'100%'}} loading='lazy'/>
        </div>
        <Container className='parselWriting'>
            <Typography variant='h5' component='h5' sx={{marginTop:3}}>
                İstanbul, Çatalca, Belgrat Mahallesi Projesi
            </Typography>
            <Typography variant='body1' component='p' sx={{marginTop:3}}>
                İstanbul ilinin Çatalca ilçesine bağlı Belgrat Mahallesi'nde yer alan bu özel proje, 6 adet parselden oluşmaktadır. Parsellerin tamamı yerleri belli, parsel 182.95 m² dir. Bu parsel elektrik, doğalgaz ve su hemen parselin başındadır.
                <br/>
                <br/>
                Projemiz, İstanbul bölgesinin hem turistik hem de tarımsal ve sanayi açısından gözde ilçelerinden biri olan Çatalcanın'ın Belgrat Mahallesi yerleşimine yürüme mesafesinde konumlanmıştır. Çatalca ilçe merkezine 35 km, İstanbul il merkezine 75 km, Çatalca guzide plajlarından Yalıköye'e 15 km, Karacaköy Evcik Plajına 5 km, Ormanlı Plajına 8 km ve Çilingoza'ya 25 km, uzaklıktadır. 
                <br/>
                <br/>
                İstanbul şehir merkezine yakın olduğundan ulaşımı kolaydır. Arazinize yaz, kış rahatlıkla ulaşabiirsiniz. Günlük olarak ulaşılabilir meşe ormanlarının dibinde, kuş cıvıltıları içinde dinlenebilir ve takip eden günde işinize dinlenik olarak gidebilirsiniz.
                <br/>
                <br/>
                Bu eşsiz projede, doğanın ve huzurun tadını çıkarırken modern yaşamın tüm olanaklarına sahip olabilirsiniz!
            </Typography>
        </Container>
        <Container fixed sx={{marginTop:3, marginBottom:3}}>
            <Grid className='parselSvg' sx={{display:'flex', justifyContent:'space-around', alignContent:'center'}} gap={2}>
                <Box sx={{width:'50%'}}>
                    <Paper elevation={8} sx={{width:'100%'}}>
                        <Svgyuzellibiredortbes/>
                    </Paper>
                </Box>
                <Box sx={{width:'50%', justifyContent:'space-around', alignContent:'center'}}>
                    <Paper elevation={8} sx={{width:'100%'}}>
                        Belgart Mahallesi
                    </Paper>
                </Box>
            </Grid>
        </Container>
        <Container>
            <Typography variant='h5' component='h5'style={{textAlign:'center',marginBottom:'20px'}}>
            PROJE AİT GÖRSELLER
            </Typography>
        </Container>
        <Container>
            <div className={model ? "model open" : "model"} >
                <img src={tempimgSrc} alt='Çatalca'loading='lazy'/>
                <CloseIcon onClick={() => setModel(false)}/>
            </div>
        </Container>
        <Container>
            <div className='gallery galaryList'>
                {DataItem1051.map((item, index) =>{
                    return(
                        <div className='pics' key={index} onClick={() => getImg(item.imgSrc)}>
                            <img src={item.imgSrc} alt='Çatalca' style={{width:'100%'}} loading='lazy'/>
                        </div>
                    )
                })}
            </div>
        </Container>
        <Container sx={{padding:'20px'}}>
            <iframe src="https://www.google.com/maps/d/embed?mid=1ibHVhYUuS3Rl5h-NiwbcbQi-7Sf39h8&ehbc=2E312F&noprof=1" width="100%" height="480" alt='a' title='Belgrat'>Emlak Çatalca</iframe>
        </Container>
        <Container className='parselContact'>
            <Registration/>
        </Container>
    </>   
  )
}

export default ByuzellibireDortBes;

const DataItem1051 = [
    {   id:1, imgSrc:img1},
    {   id:2, imgSrc:img2},
    {   id:3, imgSrc:img3},
    {   id:4, imgSrc:img4},
    {   id:5, imgSrc:img5},
    {   id:6, imgSrc:img6},
    // {   id:7, imgSrc:img7},
    // {   id:8, imgSrc:img8},
    // {   id:9, imgSrc:img9},
]
