import * as React from "react"
const Svgyuzellibeseonuc = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 400 400",
    }}
    viewBox="0 0 400 400"
    {...props}
  >
    <style>
      {
        '.st4{fill:#1d1d1b;stroke:#1d1d1b;stroke-width:.25;stroke-miterlimit:10}.st5{font-family:"MyriadPro-Regular"}.st6{font-size:6.4152px}.st9{fill:#fbb03b;stroke:#000;stroke-miterlimit:10}.st10{font-size:6px}'
      }
    </style>
    <g id="katman_4">
      <path
        d="M28.7 332.6h8v8h-8z"
        style={{
          fill: "#fbb03b",
          stroke: "#fbb03b",
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M28.7 348.1h8v8h-8z"
        style={{
          fill: "#0f0",
          stroke: "#0f0",
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M28.7 363.6h8v8h-8z"
        style={{
          fill: "#53a2da",
          stroke: "#53a2da",
          strokeMiterlimit: 10,
        }}
      />
      <text className="st4 st5 st6" transform="translate(42.02 339.846)">
        {"SATILDI"}
      </text>
      <text
        className="st4 st5 st6"
        transform="matrix(.9534 .00047 -.00049 1 42.87 356.152)"
      >
        {"SATI\u015ETA"}
      </text>
      <text className="st4 st5 st6" transform="translate(42.301 371.12)">
        {"OPS\u0130YON"}
      </text>
    </g>
    <g id="katman_3">
      <path
        d="m27.5 35.5 29 28 25 23 25 23 25 23 24 23 24 23 21 19 19 18 24 23 25 23 26 25 21 19 21 20 23 22 29 27 5-3-34-32-15-14-15-13-15-14-15-14-15-15-16-15-38-36-36-34-36-33-18-17-20-18-20-20-25-23-28-27-18-16z"
        style={{
          fill: "#ccc",
          stroke: "#ccc",
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="m28.5 32.5 44 42 26 24 21 20 19 17 18 18 36 33 35 33 55 52 59 55L391 373"
        style={{
          fill: "none",
          stroke: "#fff",
          strokeWidth: 0.5,
          strokeMiterlimit: 10,
          strokeDasharray: 5,
        }}
      />
    </g>
    <g id="katman_2">
      <path d="m29.5 30.5 9-23 26 21-17 18z" className="st9" />
      <path d="m47.5 46.5 17-18 30 24-19 21z" className="st9" />
      <path
        d="m75.5 73.5 19-21 27 21-21 23zM158.5 151.5l26-27s10 8 11 8 24 25 24 25l-25 27-36-33z"
        className="st9"
      />
      <path d="m194.5 184.5 25-27 36 35-25 26z" className="st9" />
      <path d="m230.5 218.5 25-26 23 24 14 12-24 26z" className="st9" />
      <path d="m268.5 254.5 24-26 17 14-25 27z" className="st9" />
      <path d="m284.5 269.5 25-27 16 14-26 28z" className="st9" />
      <path d="m299.5 284.5 26-28 16 14-27 28z" className="st9" />
      <path d="m314.5 298.5 27-28 15 13-27 29z" className="st9" />
      <path d="m329.5 312.5 27-29 15 13-27 29z" className="st9" />
      <path d="m344.5 325.5 27-29 8 7 3 12-23 24z" className="st9" />
      <path
        d="m359.5 339.5 23-24 11 56zM330.5 378.5l29-31 29 27-37 21z"
        className="st9"
      />
      <path d="m330.5 378.5 29-31-23-22-31 34z" className="st9" />
      <path d="m305.5 359.5 31-34-21-20-33 35z" className="st9" />
      <path d="m282.5 340.5 33-35-21-19-34 36z" className="st9" />
      <path d="m260.5 322.5 34-36-26-25-37 39z" className="st9" />
      <path d="m231.5 300.5 37-39-25-23-38 40z" className="st9" />
      <path d="m205.5 278.5 38-40-24-23-38 41 5 7z" className="st9" />
      <path d="m181.5 256.5 38-41-19-18-35 37z" className="st9" />
      <path d="m165.5 234.5 35-37-21-19-31 32z" className="st9" />
      <path d="m148.5 210.5 31-32-24-23-29 30 13 13z" className="st9" />
      <path d="m126.5 185.5 29-30-24-23-29 30z" className="st9" />
      <path d="m102.5 162.5 29-30-25-23-28 30z" className="st9" />
      <path d="m78.5 139.5 28-30-25-23-28 30z" className="st9" />
      <path d="m53.5 116.5 28-30-25-23-27 29z" className="st9" />
      <path
        d="m29.5 92.5 27-29-29-28-10 22-11 13zM100.5 96.5l21-23 23 18 20 17 20 16-26 27-18-18-19-17z"
        className="st9"
      />
    </g>
    <g id="katman_5">
      <text
        id="P01_00000005268052699477640210000002588576476935295676_"
        className="st5 st10"
        transform="rotate(.628 -6061.754 1380.51) scale(.99996)"
      >
        {"P01-769.64 m\xB2"}
      </text>
      <text
        id="P01_00000005268052699477640210000002588576476935295676_"
        className="st5 st10"
        transform="rotate(.628 -8197.045 3615.147) scale(.99996)"
      >
        {"P02-769.64 m\xB2"}
      </text>
      <text
        id="P01_00000141457028630950685200000001876680263942142358_"
        className="st5 st10"
        transform="rotate(.628 -10272.736 5996.623) scale(.99996)"
      >
        {"P03-769.64 m\xB2"}
      </text>
      <text
        id="P01_00000079479670428779973270000010536005180217334916_"
        className="st5 st10"
        transform="rotate(.628 -12421.144 8124.831) scale(.99996)"
      >
        {"P04-769.64 m\xB2"}
      </text>
      <text
        id="P01_00000058585670946469162710000004897673552249830576_"
        className="st5 st10"
        transform="rotate(.628 -14565.197 10324.328) scale(.99996)"
      >
        {"P05-769.64 m\xB2"}
      </text>
      <text
        id="P01_00000159466540821486246660000016224441923591999117_"
        className="st5 st10"
        transform="rotate(.628 -16677.52 12531.619) scale(.99996)"
      >
        {"P06-769.64 m\xB2"}
      </text>
      <text
        id="P01_00000132081044713734265470000001641404912572157314_"
        className="st5 st10"
        transform="rotate(.628 -18365.287 14819.824) scale(.99996)"
      >
        {"P07-769.64 m\xB2"}
      </text>
      <text
        id="P01_00000145759234275645660730000012636921276430438028_"
        className="st5 st10"
        transform="rotate(.628 -20768.107 16093.302) scale(.99996)"
      >
        {"P08-769.64 m\xB2"}
      </text>
      <text
        id="P01_00000107566135490068909160000011751414873495220631_"
        className="st5 st10"
        transform="rotate(.628 -22446.48 17925.655) scale(.99996)"
      >
        {"P09-1.026.18 m\xB2"}
      </text>
      <text
        id="P01_00000130612032564168436310000009334744456410836103_"
        className="st5 st10"
        transform="rotate(.628 -24442.094 20341.188) scale(.99996)"
      >
        {"P10-1.026.18 m\xB2"}
      </text>
      <text
        id="P01_00000044171062599039100420000010864414499733527189_"
        className="st5 st10"
        transform="rotate(.628 -26403.08 22674.434) scale(.99996)"
      >
        {"P11-1.026.18 m\xB2"}
      </text>
      <text
        id="P01_00000098207556282907112160000006619988002898200729_"
        className="st5 st10"
        transform="rotate(.628 -28454.527 25146.963) scale(.99996)"
      >
        {"P12-769.64 m\xB2"}
      </text>
      <text
        id="P01_00000138532830987269899660000004331700336883956669_"
        className="st5 st10"
        transform="rotate(.628 -30180.752 27032.295) scale(.99996)"
      >
        {"P13-769.64 m\xB2"}
      </text>
      <text
        id="P01_00000168075769618690681560000002909909878728734126_"
        className="st5 st10"
        transform="rotate(.628 -31948.867 29138.54) scale(.99996)"
      >
        {"P14-769.64 m\xB2"}
      </text>
      <text
        id="P01_00000116919788011152554380000015789735413103310997_"
        className="st5 st10"
        transform="rotate(.628 -34080.461 31429.192) scale(.99996)"
      >
        {"P15-769.64 m\xB2"}
      </text>
      <text
        id="P01_00000158727664148192281910000016275398627710524346_"
        className="st5 st10"
        transform="matrix(-.02707 -.9996 .9996 -.02707 384.601 357.746)"
      >
        {"P16-439.79 m\xB2"}
      </text>
      <text
        id="P01_00000054977891032738432050000009032814018288636080_"
        className="st5 st10"
        transform="rotate(-34.33 708.739 -402.729)"
      >
        {"P17-439.79 m\xB2"}
      </text>
      <text
        id="P01_00000091011830683173561860000005423455454447685294_"
        className="st5 st10"
        transform="rotate(-30.08 754.8 -466.78)"
      >
        {"P18-439.79 m\xB2"}
      </text>
      <text
        id="P01_00000081618750665209545560000010014312426744297096_"
        className="st5 st10"
        transform="rotate(-26.996 787.567 -519.482)"
      >
        {"P19-439.79 m\xB2"}
      </text>
      <text
        id="P01_00000020360946927472193320000000685788456991707548_"
        className="st5 st10"
        transform="rotate(-19.352 994.308 -752.862)"
      >
        {"P20-439.79 m\xB2"}
      </text>
      <text
        id="P01_00000167359724851885848660000005979800554437728956_"
        className="st5 st10"
        transform="rotate(-27.646 701.332 -454.106)"
      >
        {"P21-439.79 m\xB2"}
      </text>
      <text
        id="P01_00000137124884222727600840000016072897786729348255_"
        className="st5 st10"
        transform="rotate(-21.798 805.136 -582.3)"
      >
        {"P22-439.79 m\xB2"}
      </text>
      <text
        id="P01_00000000926695788008246540000002838677086742208670_"
        className="st5 st10"
        transform="rotate(.628 -20471.585 22368.543) scale(.99996)"
      >
        {"P23-1.026.18 m\xB2"}
      </text>
      <text
        id="P01_00000040566889167567951420000001738703953830900356_"
        className="st5 st10"
        transform="rotate(.628 -17441.463 19117.867) scale(.99996)"
      >
        {"P24-1.026.18 m\xB2"}
      </text>
      <text
        id="P01_00000149351825476223038580000013448911650417087390_"
        className="st5 st10"
        transform="rotate(.628 -14083.71 15635.452) scale(.99996)"
      >
        {"P25-1.026.18 m\xB2"}
      </text>
      <text
        id="P01_00000034056933189574374820000015111737614419508389_"
        className="st5 st10"
        transform="rotate(.628 -10179.806 11282.097) scale(.99996)"
      >
        {"P26-1.539.18 m\xB2"}
      </text>
      <text
        id="P01_00000110435340238347798180000003331452728935598239_"
        className="st5 st10"
        transform="rotate(.628 -6854.442 7386.147) scale(.99996)"
      >
        {"P27-575.00 m\xB2"}
      </text>
      <text
        id="P01_00000107546781693037323430000012535769817130285498_"
        className="st5 st10"
        transform="rotate(.628 -4496.983 4744.295) scale(.99996)"
      >
        {"P28-575.00 m\xB2"}
      </text>
      <text
        id="P01_00000040573271196328743520000017817201274297922740_"
        className="st5 st10"
        transform="rotate(.628 -2683.864 2911.205) scale(.99996)"
      >
        {"P29-389.27 m\xB2"}
      </text>
    </g>
  </svg>
)
export default Svgyuzellibeseonuc;
