import rldt001 from '../imageproje/104-2/02.avif';
import rldt002 from '../imageproje/106-3/03.avif';
import rldt003 from '../imageproje/145-16/06.avif';
import rldt004 from '../imageproje/153-1/04.avif';
import rldt005 from '../imageproje/155-7/02.avif';
import rldt006 from '../imageproje/105-1/01.avif';
import rldt007 from '../imageproje/101-22/01.avif';
import rldt008 from '../imageproje/151-32/01.avif';
import rldt009 from '../imageproje/115-13/00.avif';
import dp001 from '../imageproje/151-16-20-44/01.avif';


import dt001 from '../imageproje/149_5/sb_00.avif';
import dt002 from '../imageproje/101-53/00.avif';
import dt003 from '../imageproje/151-4-5/01.avif';
import dt004 from '../imageproje/107-1/01.avif';
import dt005 from '../imageproje/155-13/05.avif';


import rklm02 from '../imageproje/koyiciArsa.avif';

export const Data = [
    { 
        // ! Satışta Olan Emlak Projeleri
        id:'rlst004',
        city:'İstanbul',
        county:'Çatalca',
        village:'Belgrat Mah',
        island:'101',
        parcel:'22',
        status:'Devam',
        type:'Emlak', 
        image:rldt007,
        href:'/projeler/emlak/belgrat-yüzbire-yirmiiki',
        content:'Satışı Devam Ediyor'
    },
    { 
        id:'rlst001',
        city:'İstanbul',
        county:'Çatalca',
        village:'Belgrat Mah',
        island:'104',
        parcel:'2',
        status:'Devam',
        type:'Emlak', 
        image:rldt001,
        href:'/projeler/emlak/belgrat-yüzdörte-iki',
        content:'Satışı Devam Ediyor'
    },
    { 
        id:'rlst003',
        city:'İstanbul',
        county:'Çatalca',
        village:'Belgrat Mah',
        island:'105',
        parcel:'1',
        status:'Devam',
        type:'Emlak', 
        image:rldt006,
        href:'/projeler/emlak/belgrat-yüzbeşe-bir',
        content:'Satışı Devam Ediyor'
    },
    { 
        id:'rlst002',
        city:'İstanbul',
        county:'Çatalca',
        village:'Belgrat Mah',
        island:'106',
        parcel:'3',
        status:'Devam',
        type:'Emlak', 
        image:rldt002,
        href:'/projeler/emlak/belgrat-yüzaltıya-üç',
        content:'Satışı Devam Ediyor'
    },
    { 
        id:'rlst003',
        city:'İstanbul',
        county:'Çatalca',
        village:'Belgrat Mah',
        island:'115',
        parcel:'13',
        status:'Devam',
        type:'Emlak', 
        image:rldt009,
        href:'/projeler/emlak/belgrat-yüzonbeşe-onüç',
        content:'Satışı Devam Ediyor'
    },
    { 
        id:'rlst005',
        city:'İstanbul',
        county:'Çatalca',
        village:'Belgrat Mah',
        island:'145',
        parcel:'16',
        status:'Devam',
        type:'Emlak', 
        image:rldt003,
        href:'/projeler/emlak/belgrat-yüzkırkbeşe-onaltı',
        content:'Satışı Devam Ediyor'
    },
    { 
        id:'rlst003',
        city:'İstanbul',
        county:'Çatalca',
        village:'Belgrat Mah',
        island:'151',
        parcel:'32',
        status:'Devam',
        type:'Emlak', 
        image:rldt008,
        href:'/projeler/emlak/belgrat-yüzellibire-otuziki',
        content:'Satışı Devam Ediyor'
    },
    { 
        id:'rlst003',
        city:'İstanbul',
        county:'Çatalca',
        village:'Belgrat Mah',
        island:'153',
        parcel:'1',
        status:'Devam',
        type:'Emlak', 
        image:rldt004,
        href:'/projeler/emlak/belgrat-yüzelliüçe-bir',
        content:'Satışı Devam Ediyor'
    },
    { 
        id:'rlst003',
        city:'İstanbul',
        county:'Çatalca',
        village:'Belgrat Mah',
        island:'155',
        parcel:'7',
        status:'Devam',
        type:'Emlak', 
        image:rldt005,
        href:'/projeler/emlak/belgrat-yüzellibeşe-yedi',
        content:'Satışı Devam Ediyor'
    },
    // ! Arsa Projeleri Buradan İtibaren
    { 
        id:'rlst003',
        city:'İstanbul',
        county:'Çatalca',
        village:'Belgrat Mah',
        island:'120',
        parcel:'3',
        status:'Devam',
        type:'Arsa', 
        image:rklm02,
        href:'/projeler/belgrat-yüzyirmiye-üç',
        content:'Satışı Devam Ediyor'
    },
    // ! İnşaat Projeleri Buradan İtibaren
    { 
        id:'rlst003',
        city:'İstanbul',
        county:'Sultangazi',
        village:'Cebeci Mah',
        island:'120',
        parcel:'3',
        status:'Devam',
        type:'İnşaat', 
        image:rldt005,
        href:'/projeler/emlak/belgrat-yüzellibeşe-yedi',
        content:'Satışı Devam Ediyor'
    },
    // ! Planlanan Emlak Projeleri
    { 
        id:'rlstPl001',
        city:'İstanbul',
        county:'Çatalca',
        village:'Belgrat Mah',
        island:'151',
        parcel:'16-20-44',
        status:'Planlanan',
        type:'Planlanan', 
        image:dp001,
        href:'/projeler/planlananprojeler/belgrat-yüzellibire-onaltı-yirmi-kırkdört',
        content:'Satışa Hazırlanıyor'
    },
    
   // ! Satışı Tamamlanan
    { 
        id:'rlstPl001',
        city:'İstanbul',
        county:'Çatalca',
        village:'Belgrat Mah',
        island:'101',
        parcel:'53',
        status:'Tamamlanan',
        type:'Emlak', 
        image:dt002,
        href:'/projeler/belgrat-yüzbire-elliüç',
        content:'Satışı Tamamlandı'
    },
    { 
        id:'rlstPl001',
        city:'İstanbul',
        county:'Çatalca',
        village:'Belgrat Mah',
        island:'107',
        parcel:'1',
        status:'Tamamlanan',
        type:'Emlak', 
        image:dt004,
        href:'/projeler/belgrat-yüzyediye-bir',
        content:'Satışı Tamamlandı'
    },
    { 
        id:'rlstPl001',
        city:'İstanbul',
        county:'Çatalca',
        village:'Belgrat Mah',
        island:'144',
        parcel:'4',
        status:'Tamamlanan',
        type:'Emlak', 
        image:dt002,
        href:'/projeler/belgrat-yüzkırkdörte-dört',
        content:'Satışı Tamamlandı'
    },
    { 
        id:'rlstPl001',
        city:'İstanbul',
        county:'Çatalca',
        village:'Belgrat Mah',
        island:'149',
        parcel:'5',
        status:'Tamamlanan',
        type:'Emlak', 
        image:dt001,
        href:'/projeler/belgrat-yüzkırkdokuza-beş',
        content:'Satışı Tamamlandı'
    },
    { 
        id:'rlstPl001',
        city:'İstanbul',
        county:'Çatalca',
        village:'Belgrat Mah',
        island:'151',
        parcel:'4-5',
        status:'Tamamlanan',
        type:'Emlak', 
        image:dt003,
        href:'/projeler/belgrat-yüzellibire-dort-beş',
        content:'Satışı Tamamlandı'
    },
    { 
        id:'rlstPl001',
        city:'İstanbul',
        county:'Çatalca',
        village:'Belgrat Mah',
        island:'155',
        parcel:'13',
        status:'Tamamlanan',
        type:'Emlak', 
        image:dt005,
        href:'/projeler/belgrat-yüzellibeşe-onüç',
        content:'Satışı Tamamlandı'
    },
]

export default Data;