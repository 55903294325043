import React, { Fragment, useEffect,useState} from 'react';
import { Outlet } from 'react-router-dom';
import {Container,Grid,Typography} from '@mui/material'
import {useSelector} from 'react-redux';
import ProjectItems from '../helpers/ProjectItems';
import AdPtojrcts from '../reklam/AdPtojrcts';

function PlanlananProjecks() {
  const [proje, setProje] = useState([])
  const {dataItems} = useSelector ((store) => store.dataR)
  useEffect(() => {
    const newDataItem=dataItems.filter((item) => item.type==='Planlanan')
    setProje(newDataItem)
  },[dataItems])
  return (
  <Fragment>
      <AdPtojrcts/>
      <Container fixed sx={{marginBottom:3,marginTop:2}}>
      <Typography variant='h4' component='h4' sx={{marginBottom:2, textAlign:'center'}}>Planlanan Projelerimiz</Typography>
        <Grid fixed sx={{marginBottom:3}}>
          <Grid sx={{display:'flex', flexDirection:'row',flexWrap:'wrap', justifyContent:'center',gap:'20px'}}>
              {proje.map((Item, key) => {
                return(
                  <ProjectItems
                  key={key}
                  {...Item}
                  loading='lazy'
                  />
                )
              })}
          </Grid>
        </Grid>
      </Container>
      <Outlet/>
    </Fragment>
  )
}

export default PlanlananProjecks;