import * as React from "react"
const Svgyuzellibireotuziki = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 400 400",
    }}
    viewBox="0 0 400 400"
    {...props}
  >
    <style>
      {
        '.st1{fill:#ccc;stroke:#ccc;stroke-miterlimit:10}.st2{fill:none;stroke:#000}.st2,.st3,.st4,.st5{stroke-miterlimit:10}.st3{fill:none;stroke:#fff;stroke-width:.5;stroke-dasharray:5}.st4,.st5{fill:#0f0;stroke:#000}.st5{fill:#fbb03b}.st6{fill:#fff}.st7{font-family:"MyriadPro-Regular"}.st8{font-size:8px}.st12{fill:#1d1d1b;stroke:#1d1d1b;stroke-width:.25;stroke-miterlimit:10}.st13{font-size:7.92px}'
      }
    </style>
    <g id="katman_3">
      <path
        d="m232.5 7.1.5 25 5.9 5.2 7 .1 48.9 4 .8 12-44-2.9-6.1 5.9-13.8-9.3-4.9-4.1-6.1-.6-1.2-35.5z"
        className="st1"
      />
      <path
        d="m231.7 47.1 13.8 9.3 20.6 260.5-16.4 20.6-4.7-13.1 7.2-11.8-20.5-265.5M118.8 40.8l3.9 54.1 3.9 51.1 4.8 59.1 1.4 32 3.2 37.1 7.2 88.2 10.6-27.8-22-294.5-13 .7M153.8 334.6l-10.6 27.8 106.5-24.9-4.7-13.1-79.4 19.4-11.8-9.2"
        className="st1"
      />
      <path
        d="m290.5 8.3 4.3 33.1.8 12 6.9 200.2-5.7 29.9-30.7 33.4-16.4 20.6-6.4 20.9-7.3 14.9 29.1-5.4 5.4-19.9 48.1-52 6.9-41.9-7.8-206.2-5.2-39.1z"
        className="st1"
      />
      <path
        d="m143.2 362.4 106.5-24.9-6.4 20.9-7.3 14.9M220.7 42.4l-1.2-35.5M232.5 7.1l.5 25 5.9 5.2 55.9 4.1-4.3-33.1M312.5 8.8l5.2 39.1 7.8 206.2-6.9 41.9-48.1 52-5.4 19.9"
        className="st2"
      />
      <path
        d="m301.9 8.6 4.8 37.1 7.8 207.2-7.8 35.8-48.1 56-6.9 25.7M226.5 7l.4 29 14.8 11.3 9.1-3.8 44.8 3.6M125.8 40l21.9 299.5 15.7 12.3 94.2-24.5 1.6-11.6-20.1-262"
        className="st3"
      />
    </g>
    <g id="parseller">
        <g id="katman_4"><path d="m48.8 39.4 70 1.4 3.9 54.1-95.2 7z"/><path/></g>
        <g id="katman_4"><path d="m122.7 94.9 3.9 51.1-88.1 7.2-13.1-45.3 2.1-6z"></path></g>
        <g id="katman_4"><path d="m38.5 153.2 16.8 57.3 76.1-5.4-4.8-59.1z"/></g>
        <g id="katman_2"><path d="m55.3 210.5 9.3 32.2 68.2-5.6-1.4-32z" className="st5" /></g>
        <g id="katman_2"><path d="m64.6 242.7 10.3 36.2 61.1-4.7-3.2-37.1z" className="st5" /></g>
        <g id="katman_2"><path d="m136 274.2 7.2 88.2-41.2 10.1-27.1-93.6zM131.8 40.1l22 294.5 11.8 9.2 79.4-19.4 7.2-11.8-20.5-265.5-4.9-4.1zM295.6 53.4l6.9 200.2-5.7 29.9-30.7 33.4-20.6-260.5 6.1-5.9z"
        className="st5"/></g>
    </g>
    <g id="yazi">
      <text className="st6 st7 st8" transform="matrix(1.1142 -.08057 .07212 .9974 83.025 288.63)"
      >{"P01-522.05 m\xB2"}</text>
      
      <text className="st6 st7 st8" transform="matrix(1.1148 -.07046 .06307 .998 76.922 262.066)"
      >{"P02-261.02 m\xB2"}</text>
     
      <text className="st6 st7 st8" transform="matrix(1.1148 -.07142 .06394 .998 73.917 227.814)"
      >{"P03-261.02 m\xB2"}</text>
      
      <text className="st6 st7 st8" transform="matrix(1.1145 -.07582 .06788 .9977 69.688 183.517)"
      >{"P04-522.05 m\xB2"}</text>
      
      <text className="st6 st7 st8" transform="matrix(1.1156 -.05631 .05041 .9987 65.627 126.432)"
      >{"P05-522.05 m\xB2"}</text>
      
      <text className="st6 st7 st8" transform="matrix(1.1148 -.07047 .06308 .998 63.686 76.085)"
      >{"P06-522.05 m\xB2"}</text>
      
      <text className="st6 st7 st8" transform="matrix(1.1168 -.02445 .0219 .9998 168.719 180.565)"
      >{"P07-3,190.30 m\xB2"}</text>
      
    </g>
    <g id="katman_5">
      <path
        d="M332.9 322h9.9v9.9h-9.9z"
        style={{
          fill: "#fbb03b",
          stroke: "#fbb03b",
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M332.8 341.1h9.9v9.9h-9.9z"
        style={{
          fill: "#0f0",
          stroke: "#0f0",
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M332.8 360.3h9.9v9.9h-9.9z"
        style={{
          fill: "#0ff",
          stroke: "#0ff",
          strokeMiterlimit: 10,
        }}
      />
      <text className="st12 st7 st13" transform="translate(349.3 330.877)">
        {"SATILDI"}
      </text>
      <text className="st12 st7 st13" transform="translate(350.352 351.01)">
        {"SATI\u015ETA"}
      </text>
      <text className="st12 st7 st13" transform="translate(349.65 369.488)">
        {"OPS\u0130YON"}
      </text>
    </g>
  </svg>
)
export default Svgyuzellibireotuziki;
