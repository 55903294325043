import React from 'react';
import './AdProjects.css';
import rklm01 from '../imageproje/115-13/01.avif';
import rklm02 from '../imageproje/koyiciArsa.avif';
import rklm03 from '../imageproje/khamzadere001.avif';

export default function AdPtojrcts() {
  return (
    <div className='advertProject'>
        <div className='advertGenelWriting'>
            <h1>PROJELERİMİZ</h1>
            <h5>Geleceğe Birlikte Yön Verelim</h5>
        </div>
        <div className='advertProjectPhoto'>
            <p>Belgrat Köy Yanı</p>
            <img className='advertProjectImg' src={rklm01} alt='Emlak Çatalca' loading='lazy'/>
        </div>
        <div className='advertProjectPhoto'>
            <p>Belgrat Köy İçi</p>
            <img className='advertProjectImg' src={rklm02} alt='Emlak Çatalca' loading='lazy'/>
        </div>
        <div className='advertProjectPhoto'>
            <p>Karacaköy Yolu</p>
            <img className='advertProjectImg' src={rklm03} alt='Emlak Çatalca' loading='lazy'/>
        </div>
    </div>
  )
}
