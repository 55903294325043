import React from 'react';
import '../Style/Footer.css';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import YouTubeIcon from '@mui/icons-material/YouTube';

function Footer() {
  return (
        <div className='footer'>
            <div className='socialMedia'>
                <YouTubeIcon/>
                <FacebookIcon/>
                <InstagramIcon/>
                <TwitterIcon/>
                <WhatsAppIcon/>
                <TelegramIcon/>
            </div>
            <p className='tradeMarke'>emlakçın.com | <span><i>aikim Kuruluşudur</i></span></p>
        </div>
        
  )
}

export default Footer;