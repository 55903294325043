import React from 'react';
import '../Style/Blog.css';
import { Container,Stack,Typography } from '@mui/material';

function Blog() {
  return (
    <Container className='blogMain'>
        <Stack className='blogStack' style={{marginTop:120, marginBottom:30}} spacing={2}>
            <Typography variant='h4' component='h4' style={{color:'#3b4990'}}>
                ARSA YATIRIMINDA İDEAL BEKLEME SÜRESİ NEDİR?
            </Typography>
            <Typography variant='h5' component='h5' style={{color:'#3b4990'}}>
                ARSA YATIRIMINDA NE KADAR BEKLEMEK GEREKİR?
            </Typography>
            <Typography variant='Body2' component='p' style={{color:'#3b4990'}}>
                Arsa yatırımında yapılan en büyük yanlışlardan biri: Borsa ya da dolar mantığıyla hareket ederek, kısa vadeli al-satlarla kazanç sağlamaya çalışmak. Oysa atalarımız ne güzel söylemiş, sabreden derviş muradına erermiş! Uzun vadede en fazla getiri arsada. Ama bunun için sabretmeyi, beklemeyi bilmek gerek.
            </Typography>
            <Typography variant='h5' component='h5' style={{color:'#3b4990'}}>
                İDEAL BEKLEME SÜRESİ
            </Typography>
            <Typography variant='Body2' component='p' style={{color:'#3b4990'}}>
                Arsa yatırımında ideal bekleme süresi nedir? Bu sorunun cevabı arsadan arsaya, bölgeden bölgeye değişir. Yatırımdan maksimum kazanç elde etmek için ne kadar beklemeniz gerektiği, arsanızın potansiyelinin realize olmasını sağlayacak projelerin hayata geçme süresiyle yakından ilişkilidir. Ne demek istediğimizi biraz daha detaylı açıklayalım...
            </Typography>
            <Typography variant='h5' component='h5' style={{color:'#3b4990'}}>
                PROJELERİN BİTMESİNİ BEKLEYİN
            </Typography>
            <Typography variant='Body2' component='p' style={{color:'#3b4990'}}>
                Bir arsanın değerini, çevresindeki altyapı veya üstyapı projeleri; yani yollar, limanlar, üniversiteler, hastaneler vb. belirler. Bunlar devlet veya özel sektör projeleri olabilir.
                <Typography variant='Body2' component='br'></Typography>
                <Typography variant='Body2' component='br'></Typography>
                Büyük projelerin gelişimi, ekonomik, finansal ve siyasi koşullara bağlı olarak kimi zaman yavaşlar, kimi zaman da hızlanır. Sabırsızlık gösterip arsanızı kısa sürede elden çıkarmak, potansiyel getirisinin çok daha azıyla yetinmenize, adeta paranızı başkasına hediye etmek anlamına gelir. Maharetli yatırımcı, en düşükten alıp en yüksekten satmayı başarabilendir.
            </Typography>
            <Typography variant='h5' component='h5' style={{color:'#3b4990'}}>
                EN AZ 5 YIL BEKLEMELİ
            </Typography>
            <Typography variant='Body2' component='p' style={{color:'#3b4990'}}>
                Arsada bekleme süresi bölgeden bölgeye, projeden projeye değişir. Peki ama ortalama bir süreden söz etmek mümkün mü? <a href='/'>emlakçın.com</a> ’a göre, arsa yatırımında en az beş yıl beklenmesi gerektiğidir.
                <Typography variant='Body2' component='br'></Typography>
                <Typography variant='Body2' component='br'></Typography>
                Eğer beş yıl beklemeye tahammülünüz yoksa başka yatırım araçlarını, borsayı, doları tercih etmeniz daha doğru olur. Ama hiçbir yatırım aracının uzun vadede arsa kadar kazandırmayacağını aklınızdan çıkarmayın. Bundan 10 yıl önce <a href='/projeler/tumprojeler'>Çatalca</a> ’dan <a href='/projeler/arsa'>arsa</a>, <a href='/projeler/emlak'>emlak</a>  alanlar, aradan geçen sürede tam yüzde 4179 getiri sağladılar. Başka hangi yatırım aracı bu getiriyi sağlayabilir? Aynı dönemde örneğin dolardaki artış oranı sadece yüzde 736’dır. Bu sadece Çatalcada değil, Trakya’nın diğer bölgelerine yatırım yapanlar da sabrederek çok yüksek getiriler elde ettiler...
            </Typography>
            <Typography variant='h5' component='h5' style={{color:'#3b4990'}}>
                SABİT GETİRİLİ DEĞİL DALGALI
            </Typography>
            <Typography variant='Body2' component='p' style={{color:'#3b4990'}}>
                Arsada fiyatlar, sabit getirili yatırım araçları, banka mevduatı veya devlet tahvili gibi kesintisiz ve doğrusal şekilde artmaz. Tıpkı altın gibi dalgalanmalar olur. Yeri gelir, fiyatlarda hızlı artışlar olur, yeri gelir hafif geri çekilmeler gerçekleşir. Ama uzun vadede arsanın getirisi, banka mevduatı ve tahvil gibi sabit getirili enstrümanları beşe, ona katlar.
            </Typography>
            <Typography variant='h5' component='h5' style={{color:'#3b4990'}}>
                KÜÇÜK BİRİKİMLER SERVETE DÖNÜŞEBİLİR
            </Typography>
            <Typography variant='Body2' component='p' style={{color:'#3b4990'}}>
                Çocuğunuzun doğumunda aldığınız arsa, o üniversite çağına geldiğinde küçük bir servetin kaynağı olabilir. “Fiyatı biraz arttı” diye, o henüz ilkokuldayken arsanızı elden çıkarmak, sadece sizin değil çocuğunuzun da zarar etmesine neden olur...
            </Typography>
            <Typography variant='h5' component='h5' style={{color:'#3b4990'}}>
                <i>Ali Metin</i>
            </Typography>
        </Stack>
    </Container>

  )
}

export default Blog;