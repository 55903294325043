import {createSlice} from '@reduxjs/toolkit';
import datas from '../datas/Data';



const initialState = {
    dataItems:datas,
    href:'',
}

const dataSlice = createSlice ({
    name:'dataR',
    initialState,
    reducers: {

    },
});


export const {href} = dataSlice.actions;
export default dataSlice.reducer;