import * as React from "react"
const Svgyuzkirdokuzabes = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 400 400",
    }}
    viewBox="0 0 400 400"
    {...props}
  >
    <style>
      {
        '.st3{fill:#1d1d1b;stroke:#1d1d1b;stroke-width:.25;stroke-miterlimit:10}.st4{font-family:"MyriadPro-Regular"}.st5{font-size:4.8639px}.st7{fill:#b3b3b3;stroke:#b3b3b3;stroke-width:2}.st10,.st7,.st8,.st9{stroke-miterlimit:10}.st8{fill:none;stroke:#fff;stroke-width:.75;stroke-dasharray:5}.st10,.st9{fill:#fbb03b;stroke:#000;stroke-width:2}.st10{fill:#fff;stroke:#fff;stroke-width:.25}.st11{font-size:7px}'
      }
    </style>
   
    <g id="katman_4">
      <path
        d="M346.2 333.4h6.1v6.1h-6.1z"
        style={{
          fill: "#fbb03b",
          stroke: "#fbb03b",
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M346.2 345.1h6.1v6.1h-6.1z"
        style={{
          fill: "#0f0",
          stroke: "#0f0",
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M346.2 356.9h6.1v6.1h-6.1z"
        style={{
          fill: "#53a2da",
          stroke: "#53a2da",
          strokeMiterlimit: 10,
        }}
      />
      <text className="st3 st4 st5" transform="translate(356.312 338.83)">
        {"SATILDI"}
      </text>
      <text className="st3 st4 st5" transform="translate(356.957 351.193)">
        {"SATI\u015ETA"}
      </text>
      <text className="st3 st4 st5" transform="translate(356.526 362.541)">
        {"OPS\u0130YON"}
      </text>
    </g>
    <g id="Yol">
      <path
        d="M65.5 201.5 72 208l88-54-5-7-35 22-32.9 19.3z"
        style={{
          fill: "#b3b3b3",
          stroke: "#b3b3b3",
          strokeWidth: 2,
          strokeMiterlimit: 10,
          strokeDasharray: 8,
        }}
      />
      <path
        d="m120 97 35 50 5 7 32 45 13 18 15 21 16 23 18 26 16 22 5-8-33-48-27-37-24-34-22-32-40-57-9 4M217 54l15 30 14 31 15 31 8 16 13 28 14 29 15 30 13 28-8 3-24-49-20-41-17-37-17-34-16-32-13-30 8-3"
        className="st7"
      />
      <path
        d="m67.5 205.5 90-55.3M123.5 93.5l149.6 211.9M212.5 54.5l108 225"
        className="st8"
      />
    </g>
    <g id="Parseller">
      <path d="m12.5 144.5 38-17 37 61-22 13-17-14z" className="st9" />
      <path d="M50.5 127.5 84 113l36 56-32.5 19.5z" className="st9" />
      <path
        d="m84 113 36-16 35 50-35 22zM72 208l33-20 36 50-17 14z"
        className="st9"
      />
      <path d="m105 188 28-17 33 48-25 19z" className="st9" />
      <path d="m133 171 27-17 32 45-26 20z" className="st9" />
      <path d="m124 252 17-14 25-19 26-20 13 18-64 50z" className="st9" />
      <path d="m141 267 64-50 15 21-59 45z" className="st9" />
      <path d="m161 283 59-45 16 23-53 41z" className="st9" />
      <path d="m183 302 53-41 18 26-47 36z" className="st9" />
      <path
        d="m207 323 47-36 16 22-27 44zM275 301l6-10 35-11-24-49-50 22z"
        className="st9"
      />
      <path d="m242 253-27-37 57-26 20 41z" className="st9" />
      <path d="m215 216 57-26-17-37-64 29z" className="st9" />
      <path d="m191 182-22-32 69-31 17 34z" className="st9" />
      <path d="m169 150-20-29 73-34 16 32z" className="st9" />
      <path
        d="m149 121-20-28 80-36 13 30zM217 54l69-32 12 30-66 32z"
        className="st9"
      />
      <path
        d="m232 84 66-32 13 32-65 31zM246 115l65-31 13 33-63 29z"
        className="st9"
      />
      <path d="m261 146 63-29 6 16-61 29z" className="st9" />
      <path d="m269 162 61-29 12 30-60 27z" className="st9" />
      <path d="m282 190 60-27 12 30-58 26z" className="st9" />
      <path d="m296 219 58-26 12 31-55 25z" className="st9" />
      <path d="m311 249 13 28 56-17-14-36z" className="st9" />
    </g>
    <g id="katman_5">
      <text
        className="st10 st4 st11"
        transform="matrix(.9992 -.4994 .4471 .8945 238.284 68.58)"
      >
        {"P01-293.87 "}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(.808 -.4039 .4471 .8945 272.198 51.63)"
      >
        {"m\xB2"}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(.9992 -.4994 .4471 .8945 252.831 97.49)"
      >
        {"P02-293.87 "}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(.808 -.4039 .4471 .8945 286.746 80.54)"
      >
        {"m\xB2"}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(.9992 -.4994 .4471 .8945 265.83 128.49)"
      >
        {"P03-293.87 "}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(.808 -.4039 .4471 .8945 299.745 111.54)"
      >
        {"m\xB2"}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(.9992 -.4994 .4471 .8945 275.961 150.913)"
      >
        {"P04-146.93 "}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(.808 -.4039 .4471 .8945 309.875 133.961)"
      >
        {"m\xB2"}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(1.0202 -.4549 .4073 .9133 284.005 175.29)"
      >
        {"P05-257.14 "}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(.8251 -.3679 .4073 .9133 318.634 159.849)"
      >
        {"m\xB2"}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(1.0198 -.456 .4082 .9129 297.396 201.556)"
      >
        {"P06-257.14 "}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(.8247 -.3688 .4082 .9129 332.008 186.08)"
      >
        {"m\xB2"}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(1.0147 -.4671 .4181 .9084 311.396 231.556)"
      >
        {"P07-257.14 "}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(.8206 -.3777 .4181 .9084 345.838 215.703)"
      >
        {"m\xB2"}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(1.0182 -.4594 .4113 .9115 326.396 261.556)"
      >
        {"P08-257.14 "}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(.8234 -.3715 .4113 .9115 360.956 245.962)"
      >
        {"m\xB2"}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(1.0182 -.4594 .4113 .9115 259.044 271.153)"
      >
        {"P09-342.58 "}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(.8234 -.3715 .4113 .9115 293.604 255.559)"
      >
        {"m\xB2"}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(1.0182 -.4594 .4113 .9115 236.392 233.042)"
      >
        {"P10-342.58 "}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(.8234 -.3715 .4113 .9115 270.952 217.449)"
      >
        {"m\xB2"}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(1.0182 -.4594 .4113 .9115 214.392 195.042)"
      >
        {"P11-342.58 "}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(.8234 -.3715 .4113 .9115 248.952 179.449)"
      >
        {"m\xB2"}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(1.0182 -.4594 .4113 .9115 194.218 161.244)"
      >
        {"P12-342.58 "}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(.8234 -.3715 .4113 .9115 228.778 145.65)"
      >
        {"m\xB2"}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(1.0182 -.4594 .4113 .9115 174.218 129.244)"
      >
        {"P13-342.58 "}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(.8234 -.3715 .4113 .9115 208.778 113.65)"
      >
        {"m\xB2"}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(1.0182 -.4594 .4113 .9115 160.218 99.244)"
      >
        {"P14-342.58 "}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(.8234 -.3715 .4113 .9115 194.778 83.65)"
      >
        {"m\xB2"}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(.599 .9429 -.8441 .5362 102.956 117.4)"
      >
        {"P15-352.53 "}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(.4844 .7625 -.8441 .5362 123.287 149.403)"
      >
        {"m\xB2"}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(.599 .9429 -.8441 .5362 71.283 131.655)"
      >
        {"P16-352.53 "}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(.4844 .7625 -.8441 .5362 91.614 163.658)"
      >
        {"m\xB2"}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(.599 .9429 -.8441 .5362 41.283 148.655)"
      >
        {"P17-352.53 "}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(.4844 .7625 -.8441 .5362 61.614 180.658)"
      >
        {"m\xB2"}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(.652 .907 -.812 .5837 96.283 203.655)"
      >
        {"P18-257.14 "}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(.5273 .7335 -.812 .5837 118.415 234.441)"
      >
        {"m\xB2"}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(.652 .907 -.812 .5837 122.209 186.523)"
      >
        {"P19-257.14 "}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(.5273 .7335 -.812 .5837 144.34 217.309)"
      >
        {"m\xB2"}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(.652 .907 -.812 .5837 147.209 169.523)"
      >
        {"P20-257.14 "}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(.5273 .7335 -.812 .5837 169.34 200.309)"
      >
        {"m\xB2"}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(.89 -.6751 .6044 .7967 149.209 248.524)"
      >
        {"P21-257.14 "}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(.7197 -.546 .6044 .7967 179.416 225.61)"
      >
        {"m\xB2"}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(.89 -.6751 .6044 .7967 165.706 266.976)"
      >
        {"P22-257.14 "}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(.7197 -.546 .6044 .7967 195.914 244.062)"
      >
        {"m\xB2"}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(.89 -.6751 .6044 .7967 184.706 284.976)"
      >
        {"P23-257.14 "}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(.7197 -.546 .6044 .7967 214.914 262.062)"
      >
        {"m\xB2"}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(.89 -.6751 .6044 .7967 206.706 307.976)"
      >
        {"P24-257.14 "}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(.7197 -.546 .6044 .7967 236.914 285.062)"
      >
        {"m\xB2"}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(.89 -.6751 .6044 .7967 225.706 328.976)"
      >
        {"P25-257.14 "}
      </text>
      <text
        className="st10 st4 st11"
        transform="matrix(.7197 -.546 .6044 .7967 255.914 306.062)"
      >
        {"m\xB2"}
      </text>
    </g>
  </svg>
)
export default Svgyuzkirdokuzabes;
