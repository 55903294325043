import { useEffect, useRef, useState } from 'react';
import '../Style/ControlledCarousel.css';
import img1 from '../image/g01.avifs';
import img2 from '../image/g02.avif';
import img3 from '../image/g03.avif';
import img4 from '../image/g04.avif';
import img5 from '../image/g05.avif';
import img6 from '../image/g06.avif';
import img7 from '../image/g07.avif';
import img8 from '../image/g08.avif';
import img9 from '../image/g09.avif';
import img10 from '../image/g10.avif';
import img11 from '../image/g12.avif';
import img12 from '../image/g12.avif';


function ControlledCarousel() {
    const [current, setcurrent] = useState(0);
    const [autoPlay, setAutoPlay] = useState(true);
    let timeOut=useRef(null);
    useEffect(() => {
        timeOut.current= autoPlay && setTimeout(() => slideRight(),2500)
    })
    const slideRight= () => {
        setcurrent(current === carouselData.length-1 ? 0 : current+1)
    }
    const slideLeft= () => {
        setcurrent(current === 0 ? carouselData.length-1 : current-1)
    }
    
  return (
    <div className='Appp'>
        <div className='carousel' onMouseEnter={() => {setAutoPlay(false); clearTimeout(timeOut)}} onMouseLeave={() => setAutoPlay(true)}>
            <div className='carousel_wrapper'>
                {carouselData.map((image,index) => {
                    return(
                        <div key={index} className={index===current ? 'carousel_card carousel_card-active' :'carousel_card'}>
                            <img className='card_image' src={image.imgSrc} alt="Çatalca Emlak" />
                            <div className='card_overlay'>
                                <h2 className='card_title' content='h2'>{image.id}</h2>
                            </div>
                        </div>
                    );
                })}
                <div className="carousel_arrow_left" onClick={slideLeft}>&lsaquo;</div>
                <div className="carousel_arrow_right" onClick={slideRight}>&rsaquo;</div>
                <div className="carousel_paggination">
                    {carouselData.map((_,index) => {
                         return(
                            <div 
                                key={index} 
                                className={index===current ? 'paggination_dot paggination_dot-active' :'paggination_dot'}
                                onClick={() => setcurrent(index)}
                            ></div>
                        );
                    })
                    }
                </div>
            </div>
        </div>
    </div>
    
  )
}

export default ControlledCarousel;

const carouselData = [
    {   id:1, imgSrc:img1},
    {   id:2, imgSrc:img2},
    {   id:3, imgSrc:img3},
    {   id:4, imgSrc:img4},
    {   id:5, imgSrc:img5},
    {   id:6, imgSrc:img6},
    {   id:7, imgSrc:img7},
    {   id:8, imgSrc:img8},
    {   id:9, imgSrc:img9},
    {   id:10, imgSrc:img10},
    {   id:11, imgSrc:img11},
    {   id:12, imgSrc:img12},
]