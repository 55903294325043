import * as React from "react"
const Svgyuzbireelliuc = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 250 400",
    }}
    viewBox="0 0 250 400"
    {...props}
  >
    <style>
      {
        '.st4{fill:#1d1d1b;stroke:#000;stroke-width:.25;stroke-miterlimit:10}.st5{font-family:"MyriadPro-Regular"}.st6{font-size:4.8639px}.st11,.st12,.st9{fill:none;stroke:#fff;stroke-width:.25;stroke-miterlimit:10}.st11,.st12{fill:#fbb03b;stroke:#000;stroke-width:2}.st12{fill:#fff;stroke:#fff;stroke-width:.25}.st13{font-size:6px}'
      }
    </style>
    <g id="katman_2">
      <path
        d="M20.4 359.6h6.1v6.1h-6.1z"
        style={{
          fill: "#fbb03b",
          stroke: "#fbb03b",
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M20.4 371.4h6.1v6.1h-6.1z"
        style={{
          fill: "#0f0",
          stroke: "#0f0",
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M20.4 383.1h6.1v6.1h-6.1z"
        style={{
          fill: "#3fa9f5",
          stroke: "#3fa9f5",
          strokeMiterlimit: 10,
        }}
      />
      <text className="st4 st5 st6" transform="translate(30.538 365.088)">
        {"SATILDI"}
      </text>
      <text className="st4 st5 st6" transform="translate(31.184 377.452)">
        {"SATI\u015ETA"}
      </text>
      <text className="st4 st5 st6" transform="translate(30.752 388.8)">
        {"OPS\u0130YON"}
      </text>
    </g>
    <g id="katman_4">
      <path
        d="m83 90 9.5-5.5L155 201l32 60 60 110-9 5-15-26-14-27-14-26-21-38-23-44-23-43-22-40-6-11z"
        style={{
          fill: "#ccc",
          stroke: "#ccc",
          strokeWidth: 2,
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M92.5 84.5 181 248l42 77 25 46"
        style={{
          fill: "none",
          stroke: "#000",
          strokeWidth: 2,
          strokeMiterlimit: 10,
        }}
      />
      <path d="m87.7 87.1 1.9 3.5" className="st9" />
      <path
        d="m93.5 97.8 145.9 269.1"
        style={{
          fill: "none",
          stroke: "#fff",
          strokeWidth: 0.25,
          strokeMiterlimit: 10,
          strokeDasharray: "8.1633,8.1633",
        }}
      />
      <path d="m241.3 370.5 1.9 3.5" className="st9" />
    </g>
    <g id="katman_3">
      <path
        d="m23.5 77.5 13-44 21-14 35 65-49 27zM43.5 111.5 61 143l39-22-17-31zM61 143l5 10 40-21-6-11z"
        className="st11"
      />
      <path d="m66 153 40-21 22 40-39 21z" className="st11" />
      <path
        d="m128 172 23 43-39 21-23-43zM112 236l39-21 23 44-38 21zM136 280l38-21 21 38-38 20z"
        className="st11"
      />
      <path
        d="m157 317 38-20 14 26-37 21zM172 344l37-21 14 27-37 20z"
        className="st11"
      />
      <path d="m186 370 37-20 15 26-37 21z" className="st11" />
    </g>
    <g id="katman_5">
      <text
        className="st12 st5 st13"
        transform="matrix(.5511 -.9717 .8699 .4933 205.575 389.586)"
      >
        {"P01-200.00 "}
      </text>
      <text
        className="st12 st5 st13"
        transform="matrix(.4456 -.7858 .8699 .4933 221.607 361.317)"
      >
        {"m\xB2"}
      </text>
      <text
        className="st12 st5 st13"
        transform="matrix(.5511 -.9717 .8699 .4933 190.52 362.515)"
      >
        {"P02-200.00 "}
      </text>
      <text
        className="st12 st5 st13"
        transform="matrix(.4456 -.7858 .8699 .4933 206.552 334.245)"
      >
        {"m\xB2"}
      </text>
      <text
        className="st12 st5 st13"
        transform="matrix(.5511 -.9717 .8699 .4933 175.52 337.515)"
      >
        {"P03-200.00 "}
      </text>
      <text
        className="st12 st5 st13"
        transform="matrix(.4456 -.7858 .8699 .4933 191.552 309.245)"
      >
        {"m\xB2"}
      </text>
      <text
        className="st12 st5 st13"
        transform="matrix(.5511 -.9717 .8699 .4933 158.303 303.993)"
      >
        {"P03-200.00 "}
      </text>
      <text
        className="st12 st5 st13"
        transform="matrix(.4456 -.7858 .8699 .4933 174.335 275.724)"
      >
        {"m\xB2"}
      </text>
      <text
        className="st12 st5 st13"
        transform="matrix(.5511 -.9717 .8699 .4933 137.303 264.993)"
      >
        {"P04-257.00 "}
      </text>
      <text
        className="st12 st5 st13"
        transform="matrix(.4456 -.7858 .8699 .4933 153.335 236.724)"
      >
        {"m\xB2"}
      </text>
      <text
        className="st12 st5 st13"
        transform="matrix(.5511 -.9717 .8699 .4933 112.303 220.385)"
      >
        {"P05-257.00 "}
      </text>
      <text
        className="st12 st5 st13"
        transform="matrix(.4456 -.7858 .8699 .4933 128.335 192.115)"
      >
        {"m\xB2"}
      </text>
      <text
        className="st12 st5 st13"
        transform="matrix(.5511 -.9717 .8699 .4933 89.303 178.34)"
      >
        {"P06-257.00 "}
      </text>
      <text
        className="st12 st5 st13"
        transform="matrix(.4456 -.7858 .8699 .4933 105.335 150.072)"
      >
        {"m\xB2"}
      </text>
      <text
        className="st12 st5 st13"
        transform="matrix(.5511 -.9717 .8699 .4933 64.303 133.037)"
      >
        {"P08-335.35 "}
      </text>
      <text
        className="st12 st5 st13"
        transform="matrix(.4456 -.7858 .8699 .4933 80.335 104.768)"
      >
        {"m\xB2"}
      </text>
      <text
        className="st12 st5 st13"
        transform="matrix(.5511 -.9717 .8699 .4933 73.303 145.776)"
      >
        {"P07-47.91 "}
      </text>
      <text
        className="st12 st5 st13"
        transform="matrix(.4456 -.7858 .8699 .4933 87.639 120.498)"
      >
        {"m\xB2"}
      </text>
      <text
        className="st12 st5 st13"
        transform="matrix(.5511 -.9717 .8699 .4933 46.303 89.472)"
      >
        {"P09-553.19 "}
      </text>
      <text
        className="st12 st5 st13"
        transform="matrix(.4456 -.7858 .8699 .4933 62.335 61.202)"
      >
        {"m\xB2"}
      </text>
    </g>
  </svg>
)
export default Svgyuzbireelliuc;
