import * as React from "react"
const Svgyuellibeseyedi = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 640 480",
    }}
    viewBox="0 0 640 480"
    {...props}
  >
    <style>
      {
        '.st0{display:none}.st1{fill:#b3b3b3}.st4,.st5,.st6{fill:#0f0;stroke:none;stroke-width:4;stroke-miterlimit:12}.st5,.st6{fill:#fbb03b}.st6{opacity:.3;fill:none}.st7{font-family:"MyriadPro-Regular"}.st8{font-size:12px}.st9{fill:none;stroke:#000;stroke-width:.5}.st10{font-size:14px}.st11{font-size:14.2777px}.st15{fill:#1d1d1b;stroke:#1d1d1b;stroke-width:.25;stroke-miterlimit:10}.st16{font-size:7.92px}'
      }
    </style>
    <g id="katman_5">
      <path
        d="m46.5 101.5 56 4 53 2 53-1 55 1h109l52-1h118l13 1v-13l-31-1-115 1-58 1h-122l-32-1-40 1-31-1-38-2-29-2-13-1z"
        className="st1"
      />
      <path
        d="m31 291 1-10 59 1 55 1h126l73 1 76 1 116 1 3-90 2.5-89.5L544 93l11.5 1.5v13L552 250v26l-3 21h-72l-36-1-69-1H236l-31-2h-59l-57 1z"
        className="st1"
      />
      <path
        d="m32.4 285.5 58.1 2h56l53 1 72 1h152l122 2 1-47 1-63 2-76"
        style={{
          fill: "none",
          stroke: "#fff",
          strokeWidth: 0.5,
          strokeMiterlimit: 10,
          strokeDasharray: 5,
        }}
      />
      <path
        d="m46.6 94.7 53.9 4.8 53 1h54l57 1h108l58-1h100l12.9-.3"
        style={{
          fill: "#b3b3b3",
          stroke: "#fff",
          strokeWidth: 0.5,
          strokeMiterlimit: 10,
          strokeDasharray: 5,
        }}
      />
    </g>
    <g id="katman_3">
      <path d="m550 297.5-3.5 98.4-34.1-1.1 1.8-98.5z" id="katman_41"/>
      <path d="m514.2 296.3-1.8 98.5-35.8-1.2 1.8-97.6z" id="katman_41"/>
      <path d="m476.6 393.6-35.8-.3 1.7-97.6 35.9.3v98.5" id="katman_41"/>
      <path d="m440.8 393.3-69.8-1.5 1.7-97.6 69.8 1.5z" id="katman_2" className="strokeWidth"/>
      <path d="m371 391.8-68.9-1.5 1.7-95.8 68.9-.3z" id="katman_2" className="strokeWidth"/>
      <path d="M234 388.8l-29.5-.2 2.6-95.8 29.5 1.1z" id="katman_2" className="strokeWidth"/>
      <path d="m302.1 390.3-68.1-1.5 2.6-94.9 67.2.6z" id="katman_2" className="strokeWidth"/>
      <path d="m204.5 388.6-59.1-1.4 3.5-94.9 58.2.5z" id="katman_2" className="strokeWidth"/>
      <path d="M145.4 387.2l-60-1.4 3.5-93.1 60-.4z" id="katman_2" className="strokeWidth"/>
      <path d="M85.4 385.8l-63.5-.6 10.6-93.9 56.4 1.4z" id="katman_2" className="strokeWidth"/>
      <path d="M538.4 285.7l-116.4-.9 1.7-87.8 117.3 1.9z" id="katman_2" className="strokeWidth"/>
      <path d="m422 284.8-77-.7 3.5-89.5 75.2 2.4z" id="katman_2" className="strokeWidth"/>
      <path d="m345 284.1-73.4-.6 1.7-88.7 75.2-.2z" id="katman_2" className="strokeWidth"/> 
      <path d="M271.6 283.5l-72.5-.6 3.4-88.7 70.8.6z" id="katman_2" className="strokeWidth"/>
      <path d="M199.1 282.9l-52.8-.5 2.5-89.5 53.7 1.3z" id="katman_2" className="strokeWidth"/>
      <path d="m146.3 282.4-54.6-.4 2.5-89.6 54.6.5z" id="katman_2" className="strokeWidth"/>
      <path d="M91.7 282l-58.2-.5 4.8-44.8 3.1-45.6 52.8 1.3z" id="katman_2" className="strokeWidth"/>
      <path d="M541.1 197.4l-58.2-.5-57.3-1.4-55.5-.5 2.5-87.7 57.3-1.3 56.4.5 57.3-.4z" id="katman_2" className="strokeWidth"/>
      <path d="M370.1 195l-56.4-.4 3.4-86.9 55.5-.4z" id="katman_2" className="strokeWidth"/>
      <path d="M313.7 194.6l-52.9-.5 2.6-86.8 53.7.4z" id="katman_2" className="strokeWidth"/>
      <path d="m260.8 194.1-54.6-.5 2.6-86.8 54.6.5z" id="katman_2" className="strokeWidth"/>
      <path d="M206.2 193.6l-52.8-.4.7-86 54.7-.4z" id="katman_2" className="strokeWidth"/>
      <path d="m153.4 193.2-55.5-1.4 3.4-86.8 52.8 2.2z" id="katman_2" className="strokeWidth"/>
      <path d="m97.9 191.8-56.4-.3 5.2-89.6 54.6 3.1z" id="katman_2" className="strokeWidth"/>
    </g>
    <g id="katman_4">
      <path
        d="m46.5 89.5 55.8 3.5 52.8 2.2 54.7-.4 54.6.5 53.7.4 55.5-.4 75.7-1 95.3-.2M556.5 107.5l-3 85v18l-1 33v21l-1 20-1 12"
        className="st6"
      />
    </g>
    <g id="katman_6">
      <text className="st7 st8" transform="rotate(91.521 159.345 275.572) scale(1.00005)">{"1,350.00 m\xB2"}</text>
      <circle cx={461.5} cy={149} r={10} className="st9" />
      <text className="st7 st10" transform="matrix(.04275 .9991 -.8992 .03848 456.744 145.515)"
      >
        {"1"}
      </text>
      <text
        className="st7 st8"
        transform="rotate(91.521 102.14 224.272) scale(1.00005)"
      >
        {"450.00 m\xB2"}
      </text>
      <circle cx={351.5} cy={149.5} r={10} className="st9" />
      <text
        id="_x32_"
        className="st7 st10"
        transform="matrix(.04275 .9991 -.8992 .03848 346.738 146.038)"
      >
        {"2"}
      </text>
      <text
        className="st7 st8"
        transform="rotate(91.521 76.114 196.98) scale(1.00005)"
      >
        {"450.00 m\xB2"}
      </text>
      <circle cx={297.5} cy={147.5} r={10} className="st9" />
      <text
        id="_x32__00000103956994868074905720000014946659307786475441_"
        className="st7 st10"
        transform="matrix(.04275 .9991 -.8992 .03848 292.738 144.038)"
      >
        {"3"}
      </text>
      <text
        className="st7 st8"
        transform="rotate(91.521 49.088 168.713) scale(1.00005)"
      >
        {"450.00 m\xB2"}
      </text>
      <circle cx={241.5} cy={145.5} r={10} className="st9" />
      <text
        id="_x32__00000069396062384242327510000012482644025687100291_"
        className="st7 st10"
        transform="matrix(.04275 .9991 -.8992 .03848 236.738 142.038)"
      >
        {"4"}
      </text>
      <text
        className="st7 st8"
        transform="rotate(91.521 23.075 142.408) scale(1.00005)"
      >
        {"450.00 m\xB2"}
      </text>
      <circle cx={188.5} cy={144.5} r={10} className="st9" />
      <text
        id="_x32__00000036241474530994610860000004442454521035530149_"
        className="st7 st10"
        transform="matrix(.04275 .9991 -.8992 .03848 183.738 141.038)"
      >
        {"5"}
      </text>
      <text
        className="st7 st8"
        transform="rotate(91.521 -5.9 116.142) scale(1.00005)"
      >
        {"450.00 m\xB2"}
      </text>
      <circle cx={132.5} cy={146.5} r={10} className="st9" />
      <text
        id="_x32__00000046331597067244008160000004211941390610638773_"
        className="st7 st10"
        transform="matrix(.0307 .7173 -.8992 .03848 127.735 143.038)"
      >
        {"6"}
      </text>
      <text
        className="st7 st8"
        transform="rotate(91.521 -30.938 88.836) scale(1.00005)"
      >
        {"450.00 m\xB2"}
      </text>
      <circle cx={79.5} cy={143.5} r={10} className="st9" />
      <text
        id="_x32__00000020362311074674269320000013558854275591543966_"
        className="st7 st10"
        transform="matrix(.04275 .9991 -.8992 .03848 74.738 140.038)"
      >
        {"7"}
      </text>
      <text
        className="st7 st8"
        transform="rotate(91.521 126.306 334.503) scale(1.00005)"
      >
        {"900.00 m\xB2"}
      </text>
      <circle cx={486.5} cy={238.5} r={10} className="st9" />
      <text
        id="_x32__00000029043373953516702120000012501609195221798073_"
        className="st7 st10"
        transform="matrix(.04275 .9991 -.8992 .03848 481.738 235.038)"
      >
        {"8"}
      </text>
      <text
        className="st7 st8"
        transform="rotate(91.521 77.332 288.761) scale(1.00005)"
      >
        {"600.00 m\xB2"}
      </text>
      <circle cx={390.5} cy={240.5} r={10} className="st9" />
      <text
        id="_x32__00000044895555663302026380000011795433384589444247_"
        className="st7 st10"
        transform="matrix(.04275 .9991 -.8992 .03848 385.738 237.038)"
      >
        {"9"}
      </text>
      <text
        className="st7 st8"
        transform="rotate(91.521 41.293 250.744) scale(1.00005)"
      >
        {"600.00 m\xB2"}
      </text>
      <circle cx={315.5} cy={237.5} r={10} className="st9" />
      <text
        id="_x32__00000033347160142491640420000002511457150422167957_"
        className="st7 st10"
        transform="matrix(-.0509 .9987 -.8988 -.04581 312.175 229.78)"
      >
        {"10"}
      </text>
      <text
        className="st7 st8"
        transform="rotate(91.521 5.767 214.2) scale(1.00005)"
      >
        {"600.00 m\xB2"}
      </text>
      <circle cx={242.5} cy={235.5} r={10} className="st9" />
      <text
        id="_x32__00000057830207529840704890000014483956845865501079_"
        className="st7 st10"
        transform="matrix(-.0509 .9987 -.8988 -.04581 239.175 227.78)"
      >
        {"11"}
      </text>
      <text
        className="st7 st8"
        transform="rotate(91.521 -25.233 184.013) scale(1.00005)"
      >
        {"450.00 m\xB2"}
      </text>
      <circle cx={180.5} cy={235.5} r={10} className="st9" />
      <text
        id="_x32__00000146500706035202540670000011054073702845739170_"
        className="st7 st10"
        transform="matrix(-.0509 .9987 -.8988 -.04581 177.175 227.78)"
      >
        {"12"}
      </text>
      <text
        className="st7 st8"
        transform="rotate(91.521 -51.733 158.207) scale(1.00005)"
      >
        {"450.00 m\xB2"}
      </text>
      <circle cx={127.5} cy={235.5} r={10} className="st9" />
      <text
        id="_x32__00000146478255206546730330000014766836749546384513_"
        className="st7 st10"
        transform="matrix(-.0509 .9987 -.8988 -.04581 124.175 227.78)"
      >
        {"13"}
      </text>
      <text
        className="st7 st8"
        transform="rotate(91.521 -77.76 130.915) scale(1.00005)"
      >
        {"450.00 m\xB2"}
      </text>
      <circle cx={73.5} cy={233.5} r={10} className="st9" />
      <text
        id="_x32__00000118393486492266693960000008713731533252072881_"
        className="st7 st10"
        transform="matrix(-.0509 .9987 -.8988 -.04581 70.175 225.78)"
      >
        {"14"}
      </text>
      <text
        className="st7 st8"
        transform="rotate(91.521 103.142 411.309) scale(1.00005)"
      >
        {"325.50 m\xB2"}
      </text>
      <circle cx={535.5} cy={340.5} r={10} className="st9" />
      <text
        id="_x32__00000088847911362344248430000011949698074839402943_"
        className="st7 st11"
        transform="matrix(-.05344 1.0486 -.8988 -.04581 531.962 332.77)"
      >
        {"14"}
      </text>
      <text
        className="st7 st8"
        transform="rotate(91.521 85.642 394.267) scale(1.00005)"
      >
        {"225.00 m\xB2"}
      </text>
      <circle cx={500.5} cy={340.5} r={10} className="st9" />
      <text
        id="_x32__00000148619247540430377090000003301858139450470534_"
        className="st7 st11"
        transform="matrix(-.05344 1.0486 -.8988 -.04581 496.962 332.77)"
      >
        {"15"}
      </text>
      <text
        className="st7 st8"
        transform="rotate(91.521 67.642 376.739) scale(1.00005)"
      >
        {"325.50 m\xB2"}
      </text>
      <circle cx={464.5} cy={340.5} r={10} className="st9" />
      <text
        id="_x32__00000069386074911372998700000002493163305088192681_"
        className="st7 st11"
        transform="matrix(-.05344 1.0486 -.8988 -.04581 460.962 332.77)"
      >
        {"16"}
      </text>
      <text
        className="st7 st8"
        transform="rotate(91.521 41.63 350.433) scale(1.00005)"
      >
        {"600.00 m\xB2"}
      </text>
      <circle cx={411.5} cy={339.5} r={10} className="st9" />
      <text
        id="_x32__00000059311214502512898940000004431000479378516359_"
        className="st7 st11"
        transform="matrix(-.05344 1.0486 -.8988 -.04581 407.962 331.77)"
      >
        {"17"}
      </text>
      <text
        className="st7 st8"
        transform="rotate(91.521 5.643 316.364) scale(1.00005)"
      >
        {"600.00 m\xB2"}
      </text>
      <circle cx={340.5} cy={340.5} r={10} className="st9" />
      <text
        id="_x32__00000022559378489232272680000014214519390860118681_"
        className="st7 st11"
        transform="matrix(-.05344 1.0486 -.8988 -.04581 336.962 332.77)"
      >
        {"18"}
      </text>
      <text
        className="st7 st8"
        transform="rotate(91.521 -25.884 283.715) scale(1.00005)"
      >
        {"600.00 m\xB2"}
      </text>
      <circle cx={275.5} cy={338.5} r={10} className="st9" />
      <text
        id="_x32__00000111150066351834345950000008507382198837268655_"
        className="st7 st11"
        transform="matrix(-.05344 1.0486 -.8988 -.04581 271.962 330.77)"
      >
        {"19"}
      </text>
      <text
        className="st7 st8"
        transform="rotate(91.521 -50.397 258.87) scale(1.00005)"
      >
        {"257.14 m\xB2"}
      </text>
      <circle cx={225.5} cy={337.5} r={10} className="st9" />
      <text
        id="_x32__00000131355313982806478820000016699572328707628702_"
        className="st7 st11"
        transform="matrix(-.05344 1.0486 -.8988 -.04581 221.962 329.77)"
      >
        {"20"}
      </text>
      <text
        className="st7 st8"
        transform="rotate(91.521 -71.91 236.947) scale(1.00005)"
      >
        {"514.28 m\xB2"}
      </text>
      <circle cx={181.5} cy={336.5} r={10} className="st9" />
      <text
        id="_x32__00000034774069969344271520000012911247258345053346_"
        className="st7 st11"
        transform="matrix(-.05344 1.0486 -.8988 -.04581 177.962 328.77)"
      >
        {"21"}
      </text>
      <text
        className="st7 st8"
        transform="rotate(91.521 -102.896 207.746) scale(1.00005)"
      >
        {"514.28 m\xB2"}
      </text>
      <circle cx={120.5} cy={337.5} r={10} className="st9" />
      <text
        id="_x32__00000165227251470167408990000003199938480908857266_"
        className="st7 st11"
        transform="matrix(-.05344 1.0486 -.8988 -.04581 116.962 329.77)"
      >
        {"22"}
      </text>
      <text
        className="st7 st8"
        transform="rotate(91.521 -130.423 178.993) scale(1.00005)"
      >
        {"514.28 m\xB2"}
      </text>
      <circle cx={63.5} cy={335.5} r={10} className="st9" />
      <text
        id="_x32__00000134240134837974214790000007185134839280992142_"
        className="st7 st11"
        transform="matrix(-.05344 1.0486 -.8988 -.04581 59.962 327.77)"
      >
        {"21"}
      </text>
    </g>
    <g id="katman_7">
      <path
        d="M570.9 337.6h9.9v9.9h-9.9z"
        style={{
          fill: "#fbb03b",
          stroke: "#fbb03b",
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M570.9 356.8h9.9v9.9h-9.9z"
        style={{
          fill: "#0f0",
          stroke: "#0f0",
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M570.9 375.9h9.9v9.9h-9.9z"
        style={{
          fill: "#53a2da",
          stroke: "#53a2da",
          strokeMiterlimit: 10,
        }}
      />
      <text className="st15 st7 st16" transform="translate(587.37 346.527)">
        {"SATILDI"}
      </text>
      <text className="st15 st7 st16" transform="translate(588.42 366.66)">
        {"SATI\u015ETA"}
      </text>
      <text className="st15 st7 st16" transform="translate(587.718 385.139)">
        {"OPS\u0130YON"}
      </text>
    </g>
  </svg>
)
export default Svgyuellibeseyedi;
