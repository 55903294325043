import React, { Fragment} from 'react';
import '../Style/Home.css';
import {Container,Stack,Typography,Grid } from '@mui/material';
import AllProjectItems from '../helpers/AllProjectItems';
import {useSelector} from 'react-redux';
import ControlledCarousel from './ControlledCarousel';

function Home() {
  const {cartItems} = useSelector ((store) => store.cart)
  return (
    <Fragment>
    <Container className='homeMain'>
      <Stack style={{marginTop:105}}>
        <Typography variant='h4' component='h4'>
          Anasayfa
        </Typography>
      </Stack>
    </Container>
    <ControlledCarousel/>
    <Container className='cardEmmlakMain' fixed sx={{marginBottom:3}}>
      <Typography variant='h4' component='h4' sx={{marginBottom:5, textAlign:'center'}}>Projelerimiz</Typography>
      <Grid className='cardEmmlakGrid' container spacing={3}>
        <Grid sx={{display:'flex', flexDirection:'row',flexWrap:'wrap', justifyContent:'center',gap:'20px'}}>
            {cartItems.map((genelItem, key) => {
              return(
                <AllProjectItems
                  key={key}
                  id={genelItem.id}
                  image={genelItem.picture}
                  name={genelItem.name}
                  content={genelItem.content}
                  loading='lazy'
                />
              )
            })}
        </Grid>
      </Grid> 
     </Container>
    </Fragment>
  )
}

export default Home;