import genel001 from '../imageproje/hamzadere001.avif';
import genel002 from '../imageproje/khamzadere001.avif';
import genel003 from '../imageproje/koyyani001.avif';
import genel006 from '../imageproje/149_5/sb_00.avif';
import genel005 from '../imageproje/151-16-20-44/01.avif';


export const GeneralDatas = [
    { 
        id:'gnl001',
        name:'Emlak', 
        type:'Emlak', 
        picture:genel001,
        href:'/projeler/emlak',
        content:'Parsellenmiş tarlaları ifade etmekletedir. Tüm taraflar ile muvafakatname yapılmış ve parsel içindeki yerleri belirlenmiştir.'
    },
    { 
        id:'gnl002',
        name:'Arsa', 
        type:'Arsa', 
        picture:genel002,
        href:'/projeler/arsa',
        content:'İmar a açık yerlerdir. Belediyelerin belirledikleri oranda inşaat ruhsatı alınarak üzerine taşınmaz inşa edilebilir.'
    },
    { 
        id:'gnl003',
        name:'İnşaat', 
        type:'İnşaat', 
        picture:genel003,
        href:'/projeler/inşaat',
        content:'Belediyelerden alınmış ruhsatlara göre inşaatları yapılmış yapılardır. İçlerinde yaşama alım yapılmasına istinaden oturulabilir.'
    },
  
    { 
        id:'gnl004',
        name:'Planlanan Projeler', 
        type:'Planlanan', 
        picture:genel005,
        href:'/projeler/planlananprojeler',
        content:'Planlanan emlak, arsa ve inşaat projelerini kapsamaktadır.'
    },
    { 
        id:'gnl005',
        name:'Biten Projeler', 
        type:'Biten', 
        picture:genel006,
        href:'/projeler/tamamlananprojeler',
        content:'Tamamlamış olduğumuz emlak, arsa ve inşaat projelerini kapsamaktadır.'
    },
    { 
        id:'gnl006',
        name:'Tüm Projeler', 
        type:'Genel', 
        picture:genel002,
        href:'/projeler/tumprojeler',
        content:'Emlak, arsa ve inşaatı kapsamaktadır. Palanlanan ve biten projeler dahil değildir.'
    },
]


export default GeneralDatas;