import * as React from "react"
const Svgyuzellibiredortbes = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 400 400",
    }}
    viewBox="0 0 400 400"
    {...props}
  >
    <style>
      {
        '.st4{fill:#1d1d1b;stroke:#1d1d1b;stroke-width:.25;stroke-miterlimit:10}.st5{font-family:"MyriadPro-Regular"}.st6{font-size:6.4152px}.st7,.st8{fill:#ccc;stroke:#ccc;stroke-miterlimit:10}.st8{fill:none;stroke:#000}.st9{fill:none;stroke:#fff;stroke-width:.5}.st11,.st12,.st9{stroke-miterlimit:10}.st11{fill:none;stroke:#fff;stroke-width:.5;stroke-dasharray:8}.st12{fill:#fbb03b;stroke:#000}.st13{font-size:6px}'
      }
    </style>
    <g id="katman_4">
      <path
        d="M11.4 335.4h8v8h-8z"
        style={{
          fill: "#fbb03b",
          stroke: "#fbb03b",
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M11.4 350.9h8v8h-8z"
        style={{
          fill: "#0f0",
          stroke: "#0f0",
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M11.4 366.4h8v8h-8z"
        style={{
          fill: "#53a2da",
          stroke: "#53a2da",
          strokeMiterlimit: 10,
        }}
      />
      <text className="st4 st5 st6" transform="translate(24.733 342.59)">
        {"SATILDI"}
      </text>
      <text className="st4 st5 st6" transform="translate(25.584 358.897)">
        {"SATI\u015ETA"}
      </text>
      <text className="st4 st5 st6" transform="translate(25.015 373.865)">
        {"OPS\u0130YON"}
      </text>
    </g>
    <g id="katman_3">
      <path d="m217.5 143.5 23-34 10 6-23 33z" className="st7" />
      <path
        d="m193.5 84.5 7-10 81 45 36 19 13 7 59 32v14l-24-13-41-23-42-23-32-17-10-6-23-12-24-13M95.5 26.5l-9-8-15 16-4 21v8l-1 40 12 6v-31l7-4 62 39 35 22 26 16 31 19 42 24 25 16 61 36 1 7-19 22-17 20-40 47-15 2-81-40-50-29-34-19-4-3-36-24-53-36-14 3 99 70 87 49 84 40 22-2 88-102 1-7-47-28-40-24-42-25-30-19-10-5-21-13-43-28-78-48-1-7 21-21"
        className="st7"
      />
      <path
        d="m86.5 18.5-15 16-4 21-1 48M15.5 204.5l88 62.1 86.9 48.7 85.1 40.2 22-2 88-102 1-7"
        className="st8"
      />
      <path d="m196.5 79.5 3.5 1.9" className="st9" />
      <path
        d="m206.9 85.2 175.6 95.4"
        style={{
          fill: "none",
          stroke: "#fff",
          strokeWidth: 0.5,
          strokeMiterlimit: 10,
          strokeDasharray: "7.8372,7.8372",
        }}
      />
      <path d="m385.9 182.5 3.5 1.9" className="st9" />
      <path
        d="m222 145.5 23.5-32M91 22.4 74.5 39.5l-2 6-1 8 1 7 147 90 156 94-1 11-78 92-21 2-83-40-87-49-91-63-2.8 2.8"
        className="st11"
      />
      <path d="m71.5 103.5 2-32 2-3" className="st11" />
    </g>
    <g id="katman_2">
      <path d="m75.5 54.5-1-7 21-21 9 8 64 44-15 24z" className="st12" />
      <path d="m153.5 102.5 15-24 19 14 6-8 24 13-21 33z" className="st12" />
      <path
        d="m217.5 97.5 23 12-23 34-21-13zM227.5 148.5l23-33 32 17-25 35z"
        className="st12"
      />
      <path d="m282.5 132.5 42 23-25 37-42-25z" className="st12" />
      <path d="m299.5 192.5 25-37 41 23-26 38z" className="st12" />
      <path
        d="m365.5 178.5 24 13-3 53-47-28zM389.5 177.5l3-58-37-20-25 46z"
        className="st12"
      />
      <path d="m330.5 145.5-13-7 25-46 13 7z" className="st12" />
      <path d="m317.5 138.5 25-46-35-19-26 46z" className="st12" />
      <path
        d="m200.5 74.5 29-43 78 42-26 46zM275.5 261.5l.2-.3 12.8-21.7 61 36-17 20z"
        className="st12"
      />
      <path d="m349.5 275.5 19-22-1-7-61-36-18 29z" className="st12" />
      <path d="m250.5 246.5 31-52 25 16-18 29-13 22z" className="st12" />
      <path d="m245.5 254.5 5-8 31-52-42-24-35 59z" className="st12" />
      <path d="m204.5 229.5 35-59-31-19-36 59z" className="st12" />
      <path d="m172.5 210.5 36-59-26-16-35 60z" className="st12" />
      <path
        d="m147.5 195.5 35-60-35-22-36 60zM85.5 74.5l-7 4v31l52 32 17-28z"
        className="st12"
      />
      <path
        d="m66.5 103.5 12 6 52 32-19 32-45-27zM19.5 193.5l21-6 26-41 40.4 24.3-34.4 58.7zM106.9 170.8l4.6 2.7 33.1 20.4-36.1 59.6-36-24z"
        className="st12"
      />
      <path
        d="m108.5 253.5 36.1-59.6 2.9 1.6 25 15 9.2 5.6-35.2 59.4-34-19z"
        className="st12"
      />
      <path
        d="m181.7 216.1 22.8 13.4 41 25 5-8 25.2 14.7 56.8 34.3-40 47-15 2-34-17-47-23-50-29z"
        className="st12"
      />
    </g>
    <g id="katman_5">
      <text
        id="P03"
        className="st5 st13"
        transform="rotate(-61.64 306.119 -225.08)"
      >
        {"P01-410.18 m\xB2"}
      </text>
      <text
        id="P03"
        className="st5 st13"
        transform="rotate(-61.64 278.42 -209.916)"
      >
        {"P02-58.60 m\xB2"}
      </text>
      <text
        id="P03"
        className="st5 st13"
        transform="rotate(-61.64 255.364 -194.126)"
      >
        {"P03-299.88 m\xB2"}
      </text>
      <text
        id="P04"
        className="st5 st13"
        transform="rotate(-61.64 201.856 -162.16)"
      >
        {"P04-676.57 m\xB2"}
      </text>
      <text
        id="P05"
        className="st5 st13"
        transform="rotate(30.379 -49.96 212.732)"
      >
        {"P05-410.18 m\xB2"}
      </text>
      <text
        id="P06"
        className="st5 st13"
        transform="rotate(32.396 -85.144 337.605)"
      >
        {"P06-243.14 m\xB2"}
      </text>
      <text
        id="P07"
        className="st5 st13"
        transform="rotate(-57.825 229.367 -123.167)"
      >
        {"P07-145.89 m\xB2"}
      </text>
      <text
        id="P08"
        className="st5 st13"
        transform="rotate(-57.825 265.17 -145.52)"
      >
        {"P08-212.00 m\xB2"}
      </text>
      <text
        id="P09"
        className="st5 st13"
        transform="rotate(-57.825 302.213 -167.576)"
      >
        {"P09-300.23 m\xB2"}
      </text>
      <text
        id="P10"
        className="st5 st13"
        transform="rotate(-57.825 343.738 -192.257)"
      >
        {"P10-300.23 m\xB2"}
      </text>
      <text
        id="P11"
        className="st5 st13"
        transform="rotate(-57.825 384.245 -217.047)"
      >
        {"P11-274.24 m\xB2"}
      </text>
      <text
        id="P12"
        className="st5 st13"
        transform="rotate(29.934 -287.22 702.39)"
      >
        {"P12-340.79 m\xB2"}
      </text>
      <text
        id="P13"
        className="st5 st13"
        transform="rotate(29.367 -351.385 698.064)"
      >
        {"P13-239.48 m\xB2"}
      </text>
      <text
        id="P14"
        className="st5 st13"
        transform="rotate(-57.825 357.033 -126.112)"
      >
        {"P14-239.45 m\xB2"}
      </text>
      <text
        id="P15"
        className="st5 st13"
        transform="rotate(-57.825 323.302 -101.31)"
      >
        {"P15-455.45 m\xB2"}
      </text>
      <text
        id="P16"
        className="st5 st13"
        transform="rotate(-57.825 284.181 -78.843)"
      >
        {"P16-345.70 m\xB2"}
      </text>
      <text
        id="P17"
        className="st5 st13"
        transform="rotate(-57.825 256.487 -61.068)"
      >
        {"P17-251.99 m\xB2"}
      </text>
      <text
        id="P18"
        className="st5 st13"
        transform="rotate(-57.825 222.45 -43.154)"
      >
        {"P18-419.98 m\xB2"}
      </text>
      <text
        id="P19"
        className="st5 st13"
        transform="rotate(31.002 -134.926 219.734)"
      >
        {"P19-354.25 m\xB2"}
      </text>
      <text
        id="P20"
        className="st5 st13"
        transform="rotate(29.123 -218.757 216.904)"
      >
        {"P20-354.25 m\xB2"}
      </text>
      <text
        id="P21"
        className="st5 st13"
        transform="rotate(-57.825 213.6 43.134)"
      >
        {"P21-446.91 m\xB2"}
      </text>
      <text
        id="P22"
        className="st5 st13"
        transform="rotate(-57.825 255.823 21.655)"
      >
        {"P22-411.47 m\xB2"}
      </text>
      <text
        id="P23"
        className="st5 st13"
        transform="rotate(-57.825 294.582 -1.553)"
      >
        {"P23-411.47 m\xB2"}
      </text>
      <text
        id="P24"
        className="st5 st13"
        transform="rotate(-57.825 367.492 -43.066)"
      >
        {"P24-1,572.02 m\xB2"}
      </text>
    </g>
  </svg>
)
export default Svgyuzellibiredortbes;
