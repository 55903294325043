import * as React from 'react';
import '../helpers/ProjectItems.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

function ProjectItems({id,city,county,village,island,parcel,status,type,image,href,content}) {
  const handleClick = (e) => {
  }
  return (
    <Card className='cardGeneral' sx={{ maxWidth: 345}} >
      <CardMedia
        sx={{ height: 200 }}
        image={image}
        loading='lazy'
      ><p className={status==='Devam' ? "realItemContinue" : status==='Planlanan' ? "realItemPlaning" : "realItemFinis"}>{content}</p></CardMedia>
      <CardContent className='cardContentInfo' >
        <Typography  gutterBottom variant="body2s" component="div">
          {city+' > '+county+' > '+village+' > Ada '+island+' > Parsel '+parcel}
        </Typography>
      </CardContent>
      <CardActions>
        <a className='projetsLink' size="small" onClick={handleClick} href={href}>Projeyi İncele</a>
      </CardActions>
    </Card>
  );
}

export default ProjectItems;